import React from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
import AddIcon from '@material-ui/icons/Add';
import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { Link } from 'gatsby-theme-material-ui';
import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {utcTime, utcTimePlus} from '../utils/MantawiTime'; 
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { navigate } from '@reach/router';
import RequestFriendDialog from './RequestFriendDialog';
import AddFriendDialog from './AddFriendDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    avatar: {
      backgroundColor: red[500],
    },
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
    },
    listItemUi:{
      width: "100%",
    
    },   
    loadingUi:{
        width: "100%",        
    },
    
    avatarUi:{
       marginLeft: '-28px',
       backgroundColor: red[500],
       width: theme.spacing(3),
       height: theme.spacing(3),
    
    }, 
    buttonUi: {
      textTransform: "none",
       
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

TimeAgo.addLocale(en);
const warpText=(text)=>{
  return text.substr(0,60)+"...";
 
}
  
const YourFriends = ({userId,closedLeftDrawer}) => {

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
   
  const [friends, isLoading] = useFirestoreQuery(
        firestore.collection("friends").where("userId", "==", userId)
        .where("status","in",["friend","request"]).orderBy("updated","desc")
  );

  const [friends2, isLoading2] = useFirestoreQuery(
    firestore.collection("friends").where("friendId", "==", userId)
    .where("status","==","request").orderBy("updated","desc")
  );

  const [openR, setOpenR] = React.useState(false);
  const [openA, setOpenA] = React.useState(false);
  const [theFriend,setTheFriend] =  React.useState(null);
  const [imRequesting, setImRequesting] = React.useState(false);

  const whatColor = (status: string): string =>{
    var color = "primary";
    if(status==="request"){
      color = "secondary"
    }
    
    return color;
  }

  const handleUpdateFriendStatus = (friend) =>{
    var to=`/?kil=${userId}&fid=${friend.friendId}&where=userId`;
    setTheFriend(friend);
    if(friend.status==="request" && friend.userId === userId){
      setImRequesting(true);
      handleClickOpenR();
    }else if(friend.status==="request"){
      setImRequesting(false);
      handleClickOpenR();
    }else if(friend.hasUpdates){
      var key = `${friend.userId}${friend.friendId}`;
     
      firestore.collection("friends").doc(key).update( {hasUpdates:false} )
      .then(()=>{
        console.log("friend updated........");
      }).catch((error)=>{
        console.log("friend updated error: " + error);
        console.error(error);
      });
      navigate(to);
    }else{
      navigate(to);
    }
    closedLeftDrawer();
  }
 
  
  const handleClickOpenR = () => {
    console.log("RequestFriendDialog : handleClickOpen" )
    setOpenR(true);
  };
  const handleClickOpenA = () => {
    console.log("AddFriendDialog : handleClickOpen" )
    setOpenA(true);
  };

  const handleCloseR = (status) => {

    if(status!=='wait'){  
        var key = `${theFriend.userId}${theFriend.friendId}`;
        console.log("updating friend:  " + key);

        if(status === 'friend'){
          var keyFriend = `${theFriend.friendId}${theFriend.userId}`;
          const _datePost = utcTime();
      
          var _friend= {
            friendId: theFriend.userId,
            friendName: theFriend.userName,
            userId: theFriend.friendId,
            userName: theFriend.friendName,
            status: 'friend',  //friend, block, request, denied
            hasUpdates: true,
            created: _datePost,
            updated: _datePost,
          }
      
          firestore.collection("friends").doc(keyFriend).set(_friend).then(
            () =>{
              console.log('friends added: ' +  `${theFriend.friendId} == ${theFriend.userId}` );   
              
            }
          ).catch( (error)=>{
            console.error(error);
          });

        } 

        firestore.collection("friends").doc(key).update( {status:status} )
        .then(()=>{
          console.log("friend updated........");
        }).catch((error)=>{
          console.log("friend updated error: " + error);
          console.error(error);
        });
        setOpenR(false);
    }else{
      setOpenR(false);
    }

  };

  const handleCloseA = (status) => { 
    setOpenA(false);
  }

  return (
    <div className={classes.root}>
    {isLoading||isLoading2 && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading && 
    <List className={classes.listUi} dense>
          <Grid container direction="row" justify="center" alignItems="center">  
             
            <Grid item >     
         
            <Button
                
                color="primary"
                className={classes.buttonUi}
                endIcon={ <AddIcon />}
                onClick={handleClickOpenA}
              >
                <Typography className={classes.heading}>Add Friends</Typography> 
            </Button>
            </Grid>
            
          </Grid>

        {  friends.map((friend) =>(
        
          <ListItem  className={classes.listItemUi} key={`N-${friend._id}`} button>

             <ListItemAvatar>
             <Badge color="secondary" variant="dot">
                 <Avatar  alt={`Avatar n°${friend.name}`}
                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${friend.friendId}?alt=media`}>
                      {friend.friendName}
                  </Avatar>
                  </Badge>
              </ListItemAvatar>
            {/**  <LinkG to={ `/posts?kil=${userId}&fid=${friend.friendId}&where=userId`}  onClick={()=>{window.location.assign(`/posts?kil=${userId}&fid=${friend.friendId}&where=userId`);}}  state={ {dataLoaded:{fid:friend.friendId,where:'userId'}}}>      */}   
           {/**<Link style={{ textDecoration: 'none' }} to={ `/posts?kil=${userId}&fid=${friend.friendId}&where=userId`} onClick={ ()=>{handleUpdateFriendStatus(friend)}}>  */}    
            <Link style={{ textDecoration: 'none' }}  onClick={ ()=>{handleUpdateFriendStatus(friend)}}>    

                <ListItemText primary={ <Typography 
                                    variant="subtitle2"
                                    display="block"
                                    color="textPrimary"
                                    noWrap >
                                  {friend.friendName}
                              </Typography>} 
                
                              secondary={  <Typography
                                  component="span"
                                  variant="caption"
                                  display="block"
                                  color="textPrimary"
                                    noWrap  >
                                  {timeAgo.format(utcTimePlus(friend.updated),'twitter')}
                              </Typography>} />
              </Link>
              <ListItemSecondaryAction>
                
                <IconButton edge="end" aria-label="notification">

                  {friend.status==='request'?<NotificationsIcon  color={whatColor(friend.status)} fontSize="small" />:
                    friend.hasUpdates? <NotificationsActiveIcon color="primary" fontSize="small"  /> 
                    :<NotificationsIcon  color={whatColor(friend.status)} fontSize="small" />
                  }
                   
                </IconButton>
              </ListItemSecondaryAction>
           
          </ListItem>
            
        ))}
      </List>
    }
    {friends2.length>0 && <Typography align="center">Friend Request</Typography>}
     {!isLoading2 && 
    <List className={classes.listUi} dense>
       

        {  friends2.map((friend) =>(
        
          <ListItem  className={classes.listItemUi} key={`B-${friend._id}`} button>

             <ListItemAvatar>
             <Badge color="secondary" variant="dot">
                 <Avatar  alt={`Avatar n°${friend.name}`}
                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${friend.userId}?alt=media`}>
                      {friend.userName}
                  </Avatar>
                  </Badge>
              </ListItemAvatar>
            {/**  <LinkG to={ `/posts?kil=${userId}&fid=${friend.friendId}&where=userId`}  onClick={()=>{window.location.assign(`/posts?kil=${userId}&fid=${friend.friendId}&where=userId`);}}  state={ {dataLoaded:{fid:friend.friendId,where:'userId'}}}>      */}   
           {/**<Link style={{ textDecoration: 'none' }} to={ `/posts?kil=${userId}&fid=${friend.friendId}&where=userId`} onClick={ ()=>{handleUpdateFriendStatus(friend)}}>  */}    
            <Link style={{ textDecoration: 'none' }}  onClick={ ()=>{handleUpdateFriendStatus(friend)}}>    

                <ListItemText primary={ <Typography 
                                    variant="subtitle2"
                                    display="block"
                                    color="textPrimary"
                                    noWrap >
                                  {friend.userName}
                              </Typography>} 
                
                              secondary={  <Typography
                                  component="span"
                                  variant="caption"
                                  display="block"
                                  color="textPrimary"
                                    noWrap  >
                                  {timeAgo.format(utcTimePlus(friend.updated),'twitter')}
                              </Typography>} />
              </Link>
              <ListItemSecondaryAction>
                
                <IconButton edge="end" aria-label="notification">

                  {friend.status==='request'?<NotificationsIcon  color={whatColor(friend.status)} fontSize="small" />:
                    friend.hasUpdates? <NotificationsActiveIcon color="primary" fontSize="small"  /> 
                    :<NotificationsIcon  color={whatColor(friend.status)} fontSize="small" />
                  }
                   
                </IconButton>
              </ListItemSecondaryAction>
           
          </ListItem>
            
        ))}
      </List>
    }
  { openR&&
  <RequestFriendDialog open={openR} imRequesting={imRequesting} handleClose={handleCloseR} />
}
  {openA&&
  <AddFriendDialog open={openA}  userId={userId} handleClose={handleCloseA} />
  } 
  </div>
  );
} 
export default YourFriends;


