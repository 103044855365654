import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({    
    buttonUi: {
      textTransform: "none",
       
    } 
  }),
);
   
 

export default function RequestFriendDialog( {open, imRequesting, handleClose} ) {
   
  const classes = useStyles();
    
  return (
    <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
        <DialogTitle id="alert-dialog-title">{"Confirmation Request"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {imRequesting?
            `Waiting from your friend confirmation.`
            :
            `Please respone from your friend, request.`
          }

          </DialogContentText>
        </DialogContent>

      {imRequesting? 
        <DialogActions>
          <Button   variant="outlined" className={classes.buttonUi} onClick={()=>{handleClose('cancel')}} color="primary" autoFocus>
            Cancel your request
          </Button>
          <Button   variant="outlined" className={classes.buttonUi}  onClick={()=>{handleClose('wait')}} color="primary" autoFocus>
            Wait for Confirmation
          </Button>
        </DialogActions>
        :
        <DialogActions>
          <Button  variant="outlined" className={classes.buttonUi}  onClick={()=>{handleClose('friend')}} color="primary">
            Confirm
          </Button>
          <Button  variant="outlined" className={classes.buttonUi}  onClick={()=>{handleClose('decline')}} color="primary" autoFocus>
            Decline
          </Button>
          <Button   variant="outlined" className={classes.buttonUi}  onClick={()=>{handleClose('wait')}} color="primary" autoFocus>
            Wait
          </Button>
        </DialogActions>
        }
      </Dialog>
  );
}