
import React,{useState, useEffect} from 'react'; 
import  FormState from "../gatsby-theme-firebase/containers/FormState";
import Form from "../gatsby-theme-firebase/components/Form";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { navigate } from "gatsby"
import { firestore,firebase }  from 'gatsby-theme-firebase';
import {utcTime} from '../utils/MantawiTime'; 
import { Grid, Typography } from '@material-ui/core';
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        background: "rgba(0,0,0,0.5)",
        zIndex: theme.zIndex.drawer + 1,
    },
    loginDialog:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "28rem",
        width: "100%",
        maxHeight: "100vh",
       
        zIndex: theme.zIndex.drawer + 1,
    },
    
  }),
);


let storage = undefined;

const LoginModal = (props: { setToggleLogin: (arg0: boolean) => void;  }) =>{
    const classes = useStyles();

    // const [userProfile, setUserProfile] = useState<UserProfile>( {
    //     name: "",
    //     email: "",
    //     dob: new Date(),
    //     profilePicture: "",
    //     created: new Date(),
    //     location: "",
    //     center: [0,0],
    // });

    useEffect(()=>{
 
        if(storage === undefined){ 
          storage = firebase.storage();
        }
   
    },[]);

    const[isLoading, setIsLoading] = useState(false);
    globalThis.INDEX_RELOAD = false;

    const handleUpload = async (theImage, userUid) => {
        // const newName = `${profile.uid}.${theImage.name.split('.').pop()}` 
        const newName = `${userUid}.jpg` 
        const uploadTask = storage.ref(`images/profiles/${newName}`).put(theImage);
         uploadTask.on(
           "state_changed",
           snapshot => {
   
             const progressCount = Math.round(
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
             );
          
             
           },
           error => {
             // Error function ...
             console.error(error);
           },
           () => {
          
           }
         );
       };
    
    const saveUserProfile = async (user)=>{


            //show loading block
            console.log(' found new user: ' + user.user.uid);
            setIsLoading(true);
            globalThis.PROFILE_UID = user.user.uid;
            console.log('  globalThis.PROFILE_UID ' +  globalThis.PROFILE_UID);
            var today = utcTime();
            var newUser = false;
            var redirect = false;
            await  firestore.collection('users').doc(user.user.uid).get()
            .then(function(doc) {
              if (doc.exists) {
                  newUser = false;
                  redirect = true;
                  setIsLoading(false);
                  props.setToggleLogin(false); 

              } else {
                console.log('no document found , creating new user');
                newUser = true;
             
              }
            }) 
            .catch(function(error) {
                setIsLoading(false);
                props.setToggleLogin(false); 
                console.error("Error getting document:", error);
            });

            if(newUser){
                
                await firestore.collection('users').doc(user.user.uid).set({
                    
                    name: user.user.displayName,
                    email: user.user.email?user.user.email:"",
                    dob: "01/26/1901" ,
                    profilePicture: "",
                    created: today,
                    updated: today,
                    location: "",
                    center: [0,0],
                    providerId: user.additionalUserInfo.providerId,
                    emailVerified: user.user.emailVerified
                }).then(   ()=>{
                        // console.log("download profile.");
                        // await handleUpload(user.user.photoURL, user.user.uid);
                        // redirect = true;
                    }
                ).then(()=>{
                    console.log("user create done.");
                    setIsLoading(false);
                    props.setToggleLogin(false);  
                    redirect = true;
                  
                }).catch(function(error) {
                    setIsLoading(false);
                    props.setToggleLogin(false); 
                    console.error("Error getting document:", error);
                });
            }

            // if(redirect){
            //     window.location.assign(`/?kil=${globalThis.PROFILE_UID}&fid=post&where=postType`);
            // }


    }

    return (
        <div>
             <div className={classes.root}  onClick={() => {
                  navigate("/");
                  props.setToggleLogin(false);
              }}  >
                  {isLoading   && <p>Loading ....</p>}

              </div>
          
           
              <div className={classes.loginDialog} >
          
                  <FormState.Provider>
                      <Form
                      onSignUpSuccess={ (user) => { saveUserProfile(user) }}
                      onLoginSuccess={ (user) => { saveUserProfile(user) }}
                      onResetSuccess={() => { props.setToggleLogin(false)  }}
                      />
                  </FormState.Provider>
              </div>
    
        </div>
    )
}
export default LoginModal;
 