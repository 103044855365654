import React,  {useState, useEffect, useContext, Fragment, useRef} from 'react';
import SEO from "./SEO";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from './ProTip';
 
import Post from '../templates/Post';
import Grid from "@material-ui/core/Grid";
import { Paper, IconButton, InputBase, Divider, createStyles, makeStyles, Theme, Button, CircularProgress, List, ListItem, Fab, Menu, MenuItem, TextField, Backdrop, AppBar } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import MyMapBox from '../components/myMapBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import Switch from '@material-ui/core/Switch';
import {utcTime, sortByProperty, Copyright} from '../utils/MantawiTime'; 
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
import ListIcon from '@material-ui/icons/List';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {Place, LocationState} from '../utils/mantawiObject';
import queryString from 'query-string';
import { DotsVertical } from 'mdi-material-ui';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
//import Consumer from "../components/layout"
// problem same name 
// https://github.com/mui-org/material-ui/issues/14711
 
// import Image from '../images/index.png';
//http://www.menucool.com/rgba-color-picker
//backgroundImage:  `url(${Image})`,
 
//https://www.gatsbyjs.org/docs/gatsby-link/

//paganition
//https://www.youtube.com/watch?v=poqTHxtDXwU
//const token ='pk.eyJ1IjoianVuYWxkY2wiLCJhIjoiY2s4OHF3cmZrMGFhYzNvbXE5YzV3ZnA2dSJ9.anlICvJ8_VjbUvt5wUWVXQ';
const token = process.env.GATSBY_MAPBOX_API_KEY;
const geocodingUrl = 'https://api.mapbox.com/geocoding/v5';
const geocodingOptions = '&limit=5'
const geocodingOptions3 = '&autocomplete=true&limit=10'
const geocodingOptions2 = '&autocomplete=true&types=country,region,district,locality&limit=5'
const geocodingOptions4 = '&autocomplete=true&types=poi&limit=10'
const geocodingtypes='&autocomplete=true&types=country,region,district&limit=1'
const mapboxGeocoding = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingOptions2}`;
const mapboxGeocodingR = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingtypes}`;
 
 


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      textTransform: 'none'
    },
    divider: {
      height: 28,
      margin: 4,
    },
    headerSpacer: {
      display: 'flex',
      paddingTop: 15,
     
    },
    loaderUi:{    
      
      display: 'fixed',
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '20%',  
      marginLeft: '50%',
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '20%',
      marginLeft: '50%',
    },
  
    listUi:{
     
      width: '33vw',
      overflow: "auto",
   
    },
    searchItemList: {     
      cursor: 'pointer',
      color: 'black'
    },
    fabUi:{
      width: "100%",
    },
    menuUi:{
        width: '33vw',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    containerUi: {
  
      marginLeft: -5,
     
    },
    container: {
     
      position: 'relative',
      top: 5,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      flex: 1, 
      border: "1px solid rgb(212, 212, 212)",
    },
    appBarClipped: {
      zIndex: theme.zIndex.drawer + 1,
      height: '50px', 
      backgroundColor: 'white',
      background: 'white',
      minWidth: 345,
    },
    dotsVerticalIcon: {
      color: "#efefef"
    },
  }),
);
globalThis.COUNTER = 0;
const Posts = ({location, friends, isLoggedIn}) => {
 
  
  const classes = useStyles();
  //const { isLoggedIn, profile }  = useAuth();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
  //const userIdContext = UserIdContext.Consumer<> ;
  //var params = queryString.parse( location.search);
  const [params , setParams] = useState( queryString.parse( location.search));
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [openMap, setOpenMap] = React.useState(false);
  const [searchValue , setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [displayStyle, setDisplayStyle] = useState("none");
  const [showList, setShowList] = useState(false);
   
  const [AnchorElMapMenu, setAnchorElMapMenu] = React.useState<null | HTMLElement>(null);
  // const [whereText,setWhereText] = useState('postType');
  // const [whereValue, setWhereValue] = useState('post');
  const [isGeoLocating,setIsGeoLocating] = useState(false);
  const [isRefreshing,setIsRefreshing] = useState(false);
  
  const [showText,setShowText] = useState(true);
  const [isPostMapMenuOpen,setIsPostMapMenuOpen] = useState(false);
   
 
  var [postsDataPublic, setPostsDataPublic] = useState<firebase.firestore.DocumentData[]>([]);
  var [postsDataFriend, setPostsDataFriend] = useState<firebase.firestore.DocumentData[]>([]);
  var [postsDataPrivate, setPostsDataPrivate] = useState<firebase.firestore.DocumentData[]>([]);
  const [sortPostsData, setSortPostsData] = useState<firebase.firestore.DocumentData[]>([]);

  var [isLoading,setIsLoading] = useState(false);
  var [error, setError] = React.useState<Error | null>(null);
   
  const [colCount, setColCount] = useState(12);
  const [switchSx, setSwitchSx] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
  const [isPublicS,setIsPublicS] = useState(false);
  const [isFriendS,setIsFriendS] = useState(false);
  const [isPrivateS,setIsPrivateS] = useState(false);

  

  const [tagPosts, setTagPosts] =  useState<firebase.firestore.DocumentData[]>([]);
   
  const [isLoading2, setIsLoading2] = useState(false);
  
   

  const useFirestoreQueryTagPosts=  async <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryTagPosts: " );
    const unsubscribe =  await query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        setIsLoading2(false);
     
        setTagPosts(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
        console.log("1useFirestoreQueryTagPosts: " + tagPosts.length);
      },
      (err: Error) => {
        console.log("err useFirestoreQueryTagPosts: " + err);
        console.error(err);
        setError(err);
      } 
      
    );
    
    return () => unsubscribe();
  }
   

  const handleCloseMapMenu = () => {
    setIsPostMapMenuOpen(false);
    setAnchorElMapMenu(null);
  };

  const handleClickMapMenu = (event) => {
 
    setIsPostMapMenuOpen(true);
    setAnchorElMapMenu(event.currentTarget);
     
  };

  const onSearch = (event) => {
    // console.log("onsearch " + event.target.value);
    
       if(event.target.value.length > 2){
         fetchQuery(event.target.value);
         setSearchValue(event.target.value);
       }
     //  setDisplayStyle("inline")
   
   };

   const onSelectItem = (event,index) => {
     console.log("onselecteditem: " + index);
     setShowText(true);
     setShowList(false);
     const selectedCenter = options[index];
     setSelectedLocation(selectedCenter.name);
     setSearchValue(selectedCenter.name);
     setIsPostMapMenuOpen(false);
  
   };

   const req = (url: string, body?: any, method = 'GET') =>
   new Request(url, {
     method,
     headers: new Headers({
       Accept: 'application/json',
       'Content-Type': 'application/json',
       'Accept-Charset': 'utf-8'
     }),
     body
   });
   
   const fetchQuery = (query: string) => {
     setShowList(true);
     fetch(req(mapboxGeocoding(query)))
       .then((res: any) => res.json())
       .then((data: any) => {
         var datamap =  data.features.map((poi: any) => ({
           id: poi.id,
           center: poi.center,
           name: poi.place_name,
           place_name: poi.place_name
         }));
         setOptions( datamap );
       }).catch( (error)=>{
         console.error(error);
       });
   };

   const fetchQueryR = (query: string) => {
   // setShowList(true);
    fetch(req(mapboxGeocodingR(query)))
      .then((res: any) => res.json())
      .then((data: any) => {
        console.log(data);
        var datamap =  data.features.map((poi: any) => ({
          id: poi.id,
          center: poi.center,
          name: poi.place_name,
          place_name: poi.place_name
        }));
       // setOptions( datamap );
        let _selected = datamap[0] ;
        return _selected;
      }).then( (_selected) =>{
        console.log(_selected.center);
        setShowText(true);
         setSelectedLocation(_selected.name);
         setSearchValue(_selected.name);
         setIsGeoLocating(false);
      }).catch( (error)=>{
        console.error(error);
      });;
  };

   const handleUserLocation = () => {
   
    if(isGeoLocating){
      return;
    }
    setShowText(false);
    setIsGeoLocating(true);
    handleCloseMobile();
    navigator.geolocation.getCurrentPosition(position => {
        let newViewport = {
            height: "100vh",
            width: "100vw",
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 12
        }
        console.log(newViewport);
   
        fetchQueryR(`${position.coords.longitude},${position.coords.latitude}`);
    })
  }

  const handleMapDialogIndex = (dialogBox: string,sdLocation: any) =>{
    if(sdLocation === 'close without selecting'){
      setSelectedLocation(undefined);
      setSearchValue('');
    }else{ 
      setSelectedLocation(sdLocation.name);
      setSearchValue(sdLocation.name);
    }
    setOpenMap(false);
  }

  const handleMapOpenDialog = () =>{
    setShowText(true);
    setSelectedLocation(undefined);
    setSearchValue('');
    setOpenMap(true);
    handleCloseMobile();
  }

 
  const useFirestoreQuery3 = <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    
    const unsubscribe = query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        setIsLoading(false);
        setIsRefreshing(false);
        setSortPostsData(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
      },
      (err: Error) => {
        console.log("useFirestoreQuery3: " + err);
        console.error(err);
        setError(err);
      },
      
    );
    
    return () => unsubscribe();
  }

  var  useFirestoreQueryUser = <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    
    const unsubscribe = query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        console.log("useFirestoreQueryUser: ");
         
        setSortPostsData(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
        setIsLoading(false);
      },
      (err: Error) => {
        console.log("err useFirestoreQueryUser: " + err);
        console.error(err);
        setError(err);
      },
      
    );
    
   return () => unsubscribe();
  }
  
  const  useFirestoreQueryPublic =     <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryPublic: ");
    const unsubscribe =   query.onSnapshot(
        (querySnapshot: firebase.firestore.QuerySnapshot) => {
          setPostsDataPublic(
            querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
        setIsPublicS(false);
       
      },
      (err: Error) => {
        console.log("err useFirestoreQueryPublic: " + err);
        console.error(err);
        setError(err);
      },
      
    );
    console.log("useFirestoreQueryPublic: " + postsDataPublic.length );
   return () => unsubscribe();
  }
 
  
  const  useFirestoreQueryFriend =    <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryFriend: ");
    const unsubscribe =   query.onSnapshot(
 
        (querySnapshot: firebase.firestore.QuerySnapshot) => {
            
          
            var pdFriend =   querySnapshot.docs.map(doc => ({
              _id: doc.id,
              ...(doc.data() as T),
             }) )
             .filter( (doc2) => globalThis.MY_FRIENDS.indexOf(doc2.userId) !== -1  );
         
           // console.log("globalThis.MY_FRIENDS: " + globalThis.MY_FRIENDS);
           
            setPostsDataFriend(pdFriend);
            setIsFriendS(false);
              
      },
      (err: Error) => {
        console.log("err useFirestoreQueryFriend: " + err);
        console.error(err);
        setError(err);
      } 
       
    );
    console.log("useFirestoreQueryFriend: " + postsDataFriend.length );
    return () => unsubscribe();
  }

   
  
  const useFirestoreQueryPrivate =    <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryPrivate: ");
           
    const unsubscribe = query.onSnapshot(
         (querySnapshot: firebase.firestore.QuerySnapshot) => {

        setPostsDataPrivate(
           querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
        setIsPrivateS(false);

      },
      (err: Error) => {
        console.log("useFirestoreQueryPrivate: " + err);
        console.error(err);
        setError(err);
      },
      
    );
    console.log("useFirestoreQueryPrivate: " + postsDataPrivate.length );  
   return () => unsubscribe();
  }

  const  handleChangeSwitch = () =>{
 
    if(switchSx){
      setColCount(12);
      setSwitchSx(false);
    }else{
      setColCount(6);
      setSwitchSx(true);
    }
  }

const handleSearchLocation = () =>{

  console.log("handleSearchLocation xxxxxxxxxxxxxxxxxx");
  if(isRefreshing){
    return;
  }
  if(selectedLocation===undefined){

  }else{ 
    setSortPostsData([]);
    setIsRefreshing(true);
    setIsLoading(true);

    setIsPublicS(true);
    setIsFriendS(true);
    setIsPrivateS(true);
    handleCloseMobile();
   
    var _location = selectedLocation.split(',');
    var pCountry = undefined;
    var pDistrict =undefined;
    var pCity = undefined;
    var pOther =undefined;

    if(_location.length>0)
      pCountry = _location.pop();
    if(_location.length>0)
      pDistrict = _location.pop();
    if(_location.length>0){ 
      pCity = _location.pop();
    }
    if(_location.length>0)
      pOther = _location.pop();

     
    
    if(pDistrict === undefined){ 
   
  
      useFirestoreQueryPublic( firestore.collection('posts')
      .where("locationCountry","==", pCountry.trim())
      .where("sharedType","==","public")
      .orderBy("updated","desc")
      .limit(+process.env.GATSBY_LIMIT_PUBLIC))
 
      useFirestoreQueryFriend( firestore.collection('posts')
      .where("locationCountry","==", pCountry.trim())
      .where("sharedType","in",["friend","shared"]).orderBy("updated","desc")
      .limit(+process.env.GATSBY_LIMIT_FRIEND))
     
      useFirestoreQueryPrivate( firestore.collection('posts')
      .where("locationCountry","==", pCountry.trim())
      .where("sharedType","==","private")
      .where("userId","==",(globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:""))
      .orderBy("updated","desc")
      .limit(+process.env.GATSBY_LIMIT_PRIVATE))
     

    }else{
      var getPost = [ 
      useFirestoreQueryPublic( firestore.collection('posts')
      .where("locationCountry","==", pCountry.trim())
      .where("locationDistrict","==", pDistrict.trim())
      .where("sharedType","==","public").orderBy("updated","desc")
      .limit(+process.env.GATSBY_LIMIT_PUBLIC))
     ,
      useFirestoreQueryFriend( firestore.collection('posts')
      .where("locationCountry","==", pCountry.trim())
      .where("locationDistrict","==", pDistrict.trim())
      .where("sharedType","in",["friend","shared"]).orderBy("updated","desc")
      .limit(+process.env.GATSBY_LIMIT_FRIEND))
      ,
      useFirestoreQueryPrivate( firestore.collection('posts')
      .where("locationCountry","==", pCountry.trim())
      .where("locationDistrict","==", pDistrict.trim())
      .where("sharedType","==","private")
      .where("userId","==",(globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:""))
      .orderBy("updated","desc")
      .limit(+process.env.GATSBY_LIMIT_PRIVATE))
      ]
      var response = Promise.all(getPost);

      console.log("District: " + pDistrict.trim());
      console.log("country   " +  pCountry.trim());
    }
  }  

}
    
 const [showProgress, setShowProgress] = useState(true);
  useEffect(  () => {
     console.log("params.fid posts + " + JSON.stringify(params));

     var parseLocation  = queryString.parse( location.search) ;
 
      console.log(globalThis.PROFILE_UID);
      setIsLoading(true);
      setIsLoading2(true);
     

      setSortPostsData([]);
      setPostsDataPublic([]);
      setPostsDataFriend([]);
      setPostsDataPrivate([]);
   
       
     // setParams(queryString.parse( location.search) );
 
      useFirestoreQueryTagPosts( firestore.collection("tags").where("userId", "==", globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:"")) ;
    //  useFirestoreQueryMyFriends(firestore.collection("friends").where("userId", "==", globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:"")); 
   
    
      if(params.where==='mainPostId'){
          
        console.log("params.where===mainPostId SortPostsData");
        setIsPublicS(true);
        setIsFriendS(true);
        setIsPrivateS(true);
  
        firestore.collection('posts').doc(params.fid).get().then((doc) =>{
            if (doc.exists) {  
              setSortPostsData( [{_id: doc.id, ...doc.data() }]);
            }
        }).then(()=>{
          setIsLoading(false);
          setIsRefreshing(false); 
        })

        setIsPublicS(false);
        setIsFriendS(false);
        setIsPrivateS(false);
        
      }else  if(params.where==='userId'){

        console.log("params.where===userId postsDataPublic SortPostsData");
        setIsPublicS(true);
        setIsFriendS(true);
        setIsPrivateS(true);

        useFirestoreQueryUser( firestore.collection('posts')
        .where( "userId" ,"==", params.fid).where("sharedType","in",["public","friend","shared"])
        .orderBy("updated","desc")
        .limit(+process.env.GATSBY_LIMIT_POSTS)
        )

        setIsPublicS(false);
        setIsFriendS(false);
        setIsPrivateS(false);
     
 
      } else  {
        console.log("useFirestoreQueryPublic-useFirestoreQueryFriend-useFirestoreQueryPrivate");
        globalThis.COUNTER=globalThis.COUNTER+1;
        console.log("junaldcountercountercountercounter: " + globalThis.COUNTER);
        setIsPublicS(true);
        setIsFriendS(true);
        setIsPrivateS(true);

        var getPost = [ 

         useFirestoreQueryPublic( firestore.collection('posts')
        .where(  "postType","==", "post").where("sharedType","==","public")
        .orderBy("updated","desc")
        .limit(+process.env.GATSBY_LIMIT_PUBLIC)),
    
        useFirestoreQueryPrivate(firestore.collection('posts')
        .where(  "postType","==", "post").where("sharedType","==","private")
        .where( "userId","==",(globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:"") )
        .orderBy("updated","desc")
        .limit(+process.env.GATSBY_LIMIT_PRIVATE)),

        useFirestoreQueryFriend( firestore.collection('posts')
        .where(  "postType","==",  "post").where("sharedType","in",["friend","shared"])
       .orderBy("updated","desc")
        .limit(+process.env.GATSBY_LIMIT_FRIEND))
        ]

        var response = Promise.all(getPost);
          
      }
      // if(params.where==='mainPostId' || params.where==='userId' ){
      //   setSwitchSx(false);
      //   setColCount(12);
      // }
 
      console.log("posts wheretext and whervalue change");
      console.log(globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:"");

       
  },[params.fid]);

 

  // useEffect( ()=>{

  //   if(   isLoading  && !isPublicS && !isFriendS && !isPrivateS ){

  //    // var postF = postsDataFriend.filter( (doc2) => globalThis.MY_FRIENDS.indexOf(doc2.userId) !== -1  );
  //     var forSort = [...postsDataPublic,  ...postsDataPrivate, ...postsDataFriend];
      
  //     // async () =>{ await sortPostsData.forEach( (post) => {
  //     //      var theRef =useRef(post._id);
  //     //      postRef[post._id] = theRef;
  //     //   })
  //     //   setPostRef(postRef);
  //     // }

  //     forSort.sort(sortByProperty("updated"));
  //     setSortPostsData(forSort);
  //     setIsLoading(false);
  //     setIsRefreshing(false);
  //     console.log("count = TagPosts " + tagPosts.length);
  //     console.log("count = myFriends " + friends.length);
  //     console.log("count = postsDataPublic " + postsDataPublic.length);
  //     console.log("count = postsDataFriend " + postsDataFriend.length);
  //     console.log("count = postsDataPrivate " + postsDataPrivate.length);

  //   }
  // },[isPublicS,isFriendS, isPrivateS] );

  
  useEffect( ()=>{
   
    if(globalThis.SHOWPAGE === 2){
       
        var forSort = [...postsDataPublic,  ...postsDataPrivate, ...postsDataFriend];
      
        forSort.sort(sortByProperty("updated"));
        setSortPostsData(forSort);
        console.log("count forSort " + forSort.length);
     if(isPublicS&&isFriendS&& isPrivateS &&  isLoggedIn){
          setIsLoading(false);
          setIsLoading(false);
          setIsRefreshing(false);
          
          setIsPublicS(false);
          setIsFriendS(false);
          setIsPrivateS(false);
      
          console.log("count = TagPosts " + tagPosts.length);
          console.log("count = myFriends " + friends.length);
          console.log("count = postsDataPublic " + postsDataPublic.length);
          console.log("count = postsDataFriend " + postsDataFriend.length);
          console.log("count = postsDataPrivate " + postsDataPrivate.length);
      }
    }
    if(process.env.GATSBY_LIMIT_STATUS!=="-development"){
     // console.clear();
   }
  },[postsDataPublic,postsDataPrivate, postsDataFriend] );

   
  // useEffect( ()=>{ 
  //   console.log(`theme.breakpoints.up('md') ${matchesMD} = ${colCount} `);    
  //   if(matchesMD){
  //     setColCount(12);
  //     setSwitchSx(false);
  //   }
  // },[matchesMD ] )


  useEffect( ()=>{ 
    var parseLocation = queryString.parse( location.search);
    if(parseLocation === params){

    }else{
      setParams(parseLocation);
    }
  },[location ] )

  const handleCloseMobile = () => {
    setAnchorEl(null);
  };

  const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect( ()=>{
  //   var ht = `<script type="text/javascript">
  //     try {
  //         window._mNHandle.queue.push(function (){
  //             window._mNDetails.loadTag("624481383", "728x90", "624481383");
  //         });
  //     }
  //     catch (error) {}
  //   </script>`
  //   var doc = document.getElementById("624481383");
  //   if(doc !== null){
  //     doc.innerHTML =ht;
  //   }

    
  // },[])

  return (
  
   <Container className={classes.containerUi}>
     
          
      {( isLoading || isLoading2 || isPublicS || isFriendS || isPrivateS) && 
          <Typography variant="body2" color="textSecondary" align="center">
            <img  src={globalThis.LOADING_IMAGE} alt='loading'/>
          </Typography>   
        } 
        {( (!isLoading && !isLoading2 && !isPublicS && !isFriendS && !isPrivateS )) &&
        
          <div>
              
             <Hidden mdUp>
              <AppBar
              position="relative"
              className={classes.appBarClipped} 
              >
             <Grid item container xs={12} key="toolbar" >
       
                    <Grid container  direction="row"  justify="flex-start"  alignItems="center" >
                    <IconButton className={classes.iconButton} aria-label="menu" >
                      <LocationSearchingIcon />
                    </IconButton>
                              
                    {showText?
                        <InputBase
                          className={classes.input}
                          placeholder="Filter your posts by location. (city,region,country)"
                          value={selectedLocation}
                          onClick={()=>{setShowText(false)}}
                        /> 
                        :
                        <InputBase
                        className={classes.input}
                        placeholder="Filter your posts by location. (city,region,country)"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        defaultValue={searchValue}
                        onChange={onSearch}
                        onClick={()=>{setShowText(false)}}
                        /> 
                        }
                        <IconButton onClick={handleClickMobile}>
                          <ListIcon/>
                        </IconButton>
                  
                      </Grid>          
                      
                    <Menu
                      id="simple-menu-mobile"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMobile}
                    >
        
                        <MenuItem  color="primary"  onClick={handleSearchLocation}>
                        
                          { isRefreshing ? <CircularProgress  color="primary"  size={30}/> : < RefreshIcon  color="primary"  />  } <Typography   color="primary">Refresh</Typography>
                       
                        </MenuItem>
                          
                         
                        <MenuItem  onClick={handleUserLocation}>
                           
                           {  isGeoLocating ? <CircularProgress color="primary" size={30}/> : <MyLocationIcon  color="primary" />} <Typography   color="primary">My Location</Typography>
                       
                        </MenuItem>

                        <MenuItem onClick={handleMapOpenDialog}> 
                          
                           <SearchIcon  color="primary"  />  <Typography   color="primary"> Maps </Typography>
                          
                        </MenuItem>
                    {/**
                        <MenuItem >  
                          <Switch
                              checked={switchSx}
                              onChange={handleChangeSwitch}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </MenuItem> 
                         */}
                    </Menu>
                     { 
                      ( searchValue.length > 0 && showList)&&
                      <Container className ={classes.container}  >
                      
                            <List>
                              {
                                options.map((el, index) => (
                                  <ListItem
                                    key={index}
                                    onClick={(event) => onSelectItem(event, index)}
                                    className={classes.searchItemList}
                                    button
                                  >
                                    {el.place_name.substr(0,120)}
                                  </ListItem>
                                ))
                              }
                            </List>
                            
                      </Container> 
                    }
           

                </Grid>
            </AppBar>
             </Hidden>
             <Hidden smDown>
              <AppBar
                position="relative"
              className={classes.appBarClipped} 
              >
         <Grid item xs={12} key="toolbar">
                    <Grid container direction="row" justify="center" alignItems="center">
               <IconButton className={classes.iconButton} aria-label="menu" >
                 <LocationSearchingIcon  />
               </IconButton>
                         
              {showText?
                      <InputBase
                        className={classes.input}
                        placeholder="Filter your posts by location. (city,region,country)"
                        value={selectedLocation}
                        onClick={()=>{setShowText(false)}}
                      /> 
                      :
                       <InputBase
                       className={classes.input}
                       placeholder="Filter your posts by location. (city,region,country)"
                       inputProps={{ 'aria-label': 'search google maps' }}
                       defaultValue={searchValue}
                       onChange={onSearch}
                       onClick={()=>{setShowText(false)}}
                      /> 
                      }
               
               <Tooltip title="Refresh the posts base on your selected location.">
               <IconButton color="primary" className={classes.iconButton} aria-label="search" onClick={handleSearchLocation}>
                 {  isRefreshing ? <CircularProgress size={30}/> : <RefreshIcon />}
               </IconButton>
               </Tooltip>
  
               <Divider className={classes.divider} orientation="vertical" />
               <Tooltip title="Auto Geo locate.">
               <IconButton color="primary" className={classes.iconButton} aria-label="gel locate" onClick={handleUserLocation}>
                 {  isGeoLocating ? <CircularProgress size={30}/> : <MyLocationIcon />}
               </IconButton>
               </Tooltip>
               
               <Divider className={classes.divider} orientation="vertical" />
               <Tooltip title="Open Map screen.">
               <IconButton  color="primary" className={classes.iconButton} aria-label="search" onClick={handleMapOpenDialog}>
                 <SearchIcon />
               </IconButton>
               </Tooltip>
               {/**
               <Divider className={classes.divider} orientation="vertical" />
               <Tooltip title="Switch display to two column"> 
               <Switch
                   checked={switchSx}
                   onChange={handleChangeSwitch}
                   color="primary"
                   name="checkedB"
                  
                   inputProps={{ 'aria-label': 'primary checkbox' }}
                 />
                </Tooltip>
              */}
             </Grid>

             { 
               ( searchValue.length > 0 && showList)&&
               <Container className ={classes.container}  >
              
                    <List>
                      {
                        options.map((el, index) => (
                          <ListItem
                            key={index}
                            onClick={(event) => onSelectItem(event, index)}
                            className={classes.searchItemList}
                            button
                          >
                            {el.place_name.substr(0,120)}
                          </ListItem>
                        ))
                      }
                    </List>
                    
              </Container> 
             }
           

         </Grid>
         </AppBar>    
         </Hidden>
         
              <div className={classes.headerSpacer} />
            <Grid
             
              container
              direction="row"
              alignItems="center"
              justify="center" 
            >
             
              {  sortPostsData.map((post, index) =>(
                  
                   
                /**  <Grid  item xs={12}  md={colCount} key={post['_id']}  style={{ padding: 5 }} > */ 
                <Grid  item xs={12}  md={12} key={post['_id']}  style={{ padding: 5 }} > 
                 {/** <Post post={post} tagPost={findUserPostTag(post._id+globalThis.PROFILE_UID)}/>     */} 
                 <Post post={post} tagPost={tagPosts.find(e => e._id === post._id+globalThis.PROFILE_UID)} friend={friends.find(e => e.friendId === post.userId) } colCount={colCount} index={index} />  
                </Grid>
              ))
            } 
           
            </Grid>
            </div>
      }
 
      
      <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapDialogIndex}  source="posts"  />
 
     
    
      <Copyright/>

 

      </Container>
 
  );
}
 
export default Posts



