import React,{useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Paper, Link } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {utcTime, utcTimePlus, sortByProperty, warpText} from '../utils/MantawiTime'; 
import { navigate } from '@reach/router';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
    },
    listItemUi:{
     
      width: "auto",
      flex: 1,
    },   
    loadingUi:{
        width: "100%",        
    },
    typoUi:{
        maxHeight: 100,
        display:"block",
        overflow: 'hidden',
    },
    notificationUi:{
       flex: 1,
    },
    
    gridUi:{
      flex: 1,
      width: "100%"
    },
    inline: {
      display: 'inline',
    },
   
    
  }),
);
   
TimeAgo.addLocale(en);
 
 

const YourTagPosts = ({userId,closedLeftDrawer}) => {

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
   
  const [tagPosts, isLoading] = useFirestoreQuery(
        firestore.collection("tags").where("userId", "==", userId).where("tag","==",true).orderBy("updated","desc")
  );

//   const [sortPosts, setSortPosts] = useState([]);

//   useEffect(()=>{
    
//       if(tagPosts && tagPosts.length>0){
//         tagPosts.sort(sortByProperty("updated"))
//         setSortPosts(tagPosts);
//       }
    
//  },[tagPosts]);


 const handlePost = (post)=>{
  
  var to=`/?kil=${userId}&fid=${post.postId}&where=mainPostId`;
  if(post.hasUpdates){ 
  firestore.collection("tags").doc(post._id).update( {hasUpdates:false} )
       .then(()=>{
         console.log("post updated........");
       }).catch((error)=>{
         console.log("post updated error: " + error);
         console.error(error);
       });
     }
  navigate(to);
  closedLeftDrawer();
}

  return (
    <div className={classes.root}>
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
     
    <List className={classes.listUi} >
        { tagPosts.length === 0 &&  <Grid  container direction="row" justify="center"
  alignItems="center" >Tag your favorite post <LocalOfferIcon fontSize="small" color="primary"/> </Grid>}
        {  tagPosts.map((post) =>(
          <Link  key={post._id}  style={{ textDecoration: 'none' }}  onClick={ ()=>{handlePost(post)}}>     
          <ListItem className={classes.listItemUi} key={post._id} button alignItems="flex-start">
               <ListItemAvatar>
                 <Badge color="secondary" variant="dot">
                    <Avatar  alt={`Avatar n°${post.authorId}`}
                      src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${post.authorId}?alt=media`}>
                        {post.authorName}
                    </Avatar>
                 </Badge>
              </ListItemAvatar>
            
            <ListItemText className={classes.typoUi}   primary={ 
              
                                     <Typography
                                          variant="subtitle2"
                                          display="block"
                                          color="textPrimary"
                                          noWrap >
                                      {post.authorName}  
                                   </Typography>
                                 
                            
                             }
                       secondary={  
                                   
                                        <Typography
                                          component="span"
                                          variant="caption"
                                          display="block"
                                          color="textPrimary"
                                       
                                          >
                                         {timeAgo.format(utcTimePlus(post.created), 'twitter')}:  {warpText(post.story)}
                                        </Typography>
                                   
                                   }
                                   />
            <ListItemSecondaryAction>
                 {post.hasUpdates?<NotificationsActiveIcon color="primary" fontSize="small"  />:
                  <NotificationsIcon className={classes.notificationUi} color="primary" fontSize="small" /> }
            </ListItemSecondaryAction>
                    
          </ListItem>
           </Link> 
        ))}
      </List>
     
    }
   </div>
  );
} 
export default YourTagPosts;
