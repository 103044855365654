import React , {useState,useEffect, useRef, useCallback} from 'react';
import {useDropzone} from 'react-dropzone'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { Grid, Divider, AppBar, Toolbar, IconButton, Typography , Slide, Card, CardHeader, Avatar, CardContent, CardActions, Badge, Collapse, Container, Icon, TextareaAutosize, TextField, ListItemIcon, ListItemText, Snackbar, CardMedia} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import PostingImage from '../templates/PostingImage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import Menu, { MenuProps } from '@material-ui/core/Menu';
 
import MyMapBox from '../components/myMapBox';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
 
import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime} from '../utils/MantawiTime'; 
import { Alert, AlertTitle } from '@material-ui/lab';
import PeopleIcon from '@material-ui/icons/People';
// SHARING -- PRIVATE, FRIEND, PUBLIC
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// UPLOAD VIDEO/IMAGE
// TAG A FRIEND ???
// WHERE ARE YOU LOCATION
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PublishIcon from '@material-ui/icons/Publish';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
 
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDialog:{
        background: "rgba(0,0,0,0)",
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    expand4: {
        transform: 'rotate(0deg)',
        marginLeft: '5%',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
      border: 1,
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "none",
      minWidth: '200px',
    },
    input: {
      display: 'none',
    },
    textLocationField: {
      marginTop: '10px',
    },
    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    titleTextUi:{
      marginTop: 20,
    }
  
  }),
);
 

//https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
function linkify_o(text) {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
    });
}
function linkify(text) {
  var urlRegex =/(\b(https?|http|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
   
  var urlink=null;
  text.replace(urlRegex, function(url){
    urlink = url; 
  });
  return urlink;
  
}
  

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

let storage = undefined;

 
const Posting = (props: { openPostDialog: boolean; handleCloseDialogPost: (arg0: string) => void;  userProfile: any; matchesXS:boolean}) =>{
  const classes = useStyles();
  const messageInput = useRef(null);
  
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [widthLabel, setWidthLabel] = React.useState("md"); 
 
  const [isNewPost,setIsNewPost] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending,setIsSending] = useState(false);
  
  const handleMaxWidthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaxWidth(event.target.value as DialogProps['maxWidth']);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setFullWidth(event.target.checked);
     
    if(fullWidth){
        setWidthLabel("md");
    }else{
        setWidthLabel("sm");
    }
    setMaxWidth('md' as DialogProps['maxWidth']);
  };

  const [openMap, setOpenMap]= useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [today, setToday] = useState(new Date().toLocaleDateString());
  const [postMessage, setPostMessage] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [locationIconColor,setLocationIconColor] = useState("primary");
  //{file: []} what if this your defualt value for array
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);

  const [timerStarted,setTimerStartec] = useState(false);
  const [recordId, setRecordId] = useState(null);
  
  const [progress,setProgress] = useState([]);
  const [privacy, setPrivacy] = useState(null);
  const [openSnack,setOpenSnack] = useState(false);
  const [alertMessage,setAlertMessage] = useState("");

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log(acceptedFiles);
   
    fileLoader( Array.from(acceptedFiles));
 
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const checkClosingDialog =()=>{
    if(!isSending){ 
      props.handleCloseDialogPost("closeaway");
    }
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
   //junald
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMapDialog = (dialogBox: string,sdLocation: any) =>{
    setOpenMap(true);
    
  }

  const handleMapCloseDialog = (dialogBox: string,sdLocation: any) =>{
    setOpenMap(false);
    
    if(sdLocation !== null && sdLocation !== "close without selecting"){
      setSelectedLocation(sdLocation)
    }

  }
  // let timer   = setInterval(() => {
  //   locationIconColor ==='primary'? setLocationIconColor('secondary'):setLocationIconColor('primary')
  // }, 1000);

//https://jsmanifest.com/uploading-files-in-react-while-keeping-ui-completely-in-sync/
 
 const [overSize, setOverSize] = useState([]);
 const [overSizeMessage, setOverSizeMessage] = useState("");
 const [fileCountUpload,setFileCountUpload] = useState(0);
 
 const handleFileChange = (event)  => {
  if (event.target.files.length) {
    fileLoader( Array.from(event.target.files));
  }
 }
//  const handleFileChange = (event)  => {
    
//     setAlertMessage("size");
//     if (event.target.files.length) {
      
//       const arrFiles = Array.from(event.target.files)
//       console.log(arrFiles);
//       const newfiles = arrFiles.map((file: any, index) => {
//         var urlFile = URL.createObjectURL(file)
//         var _overSize = false;
      
//         if (file.size > 2097152) // 2 mb for bytes.
//         {
          
//           _overSize = true;
//            overSize.push(file.name);
//            setOverSize(overSize);
//         } 
// //detect file component 
// //video, audio, picture, iframe, or img,  == page, youtube.
// // link 
//         var component = "img";
//         var imageType = file.type.indexOf('image');
//         if(imageType === -1){

//         }else{
//           component = 'img'
//         }
//         return {overSize: _overSize, index:0,fileType: file.type, size: file.size, img: urlFile, fileName: file.name, author:globalThis.PROFILE_UID, progress: 0, file: file,
//           component: component
//         };
//       });
 
//    // setFiles([...files,newfiles]);
//    var osMsg = "";
//    var hasOverSize = false;
//       newfiles.map(f =>{
//           if(f.overSize){ 
//             hasOverSize = true;
//             osMsg = osMsg +  ` ${f.fileName}=${f.size}, `;
//           }else{
//             files.push(f);
//           }
//       });
//       setFiles([...files]);
//       setOverSizeMessage(osMsg);
//       setOpenSnack(hasOverSize);
//     }
//   };

  const fileLoader = (arrFiles)  => {
    
    setAlertMessage("size");
   
  
    console.log(arrFiles);
    const newfiles = arrFiles.map((file: any, index) => {
      var urlFile = URL.createObjectURL(file)
      var _overSize = false;
    
      if (file.size > 2097152) // 2 mb for bytes.
      {
        
        _overSize = true;
          overSize.push(file.name);
          setOverSize(overSize);
      } 
//detect file component 
//video, audio, picture, iframe, or img,  == page, youtube.
// link 
      var component = "img";
      var imageType = file.type.indexOf('image');
      if(imageType === -1){
        component = file.type;
      }else{
        component = 'img'
      }
      return {source: 'posting', overSize: _overSize, index:0,fileType: file.type, size: file.size, img: urlFile, fileName: file.name, author:globalThis.PROFILE_UID, progress: 0, file: file,
        component: component
      };
    });
 
   // setFiles([...files,newfiles]);
   var osMsg = "";
   var osFileMsg = "";
   var hasOverSize = false;
   var notImage = false;
      newfiles.map(f =>{
          if(f.overSize){ 
            hasOverSize = true;
            osMsg = osMsg +  ` ${f.fileName}=${f.size}, `;
          }else{
            if(f.component === 'img'){  
              files.push(f);
            }else{
              notImage = true;
              osFileMsg =` ,Some files are not images. `;
            }
          }
      });
      setFiles([...files]);

      var themsg = `You have ${overSize.length} over size files selected (2mb limit).`;
      if(hasOverSize){
        themsg = themsg + osMsg + osFileMsg; 
      }else{
        themsg = osFileMsg;
      }
                     
      setOverSizeMessage(themsg);
      setOpenSnack((hasOverSize||notImage));
    
  };

  // useEffect(()=>{
  //   if(storage === undefined){ 
  //     storage = firebase.storage();
  //   }
  // },[]);

  useEffect(()=>{
 
    if(isSending ){
    
      if(files.length>0){ 
        if(fileCountUpload >= files.length){
          console.log(files.length +"   fileCountUpload " + fileCountUpload)
          setTimeout(() => {
              publishPostRecord();
          }, 2000);
        } 
      }else{
        publishPostRecord();
      }

    }


    // if(timerStarted && selectedLocation !== null){
    // //  clearInterval(timer);
    //   setLocationIconColor('green');
    //   console.log("clearInterval")
    // }

  },[fileCountUpload]);

  const handlePostMessage = (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    var surl = linkify(value);
     
    if(surl !== null){
        var isfound =  false;
        urls.forEach(u=>{
          if(u === surl){
              isfound =true;
          }
        })
        if(!isfound){ 
         urls.push(surl);
        }
    }
    //var newText = linkify_o(value);
    setPostMessage(value );
  }

  const handlePostTitle = (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    setPostTitle(value );
  }

  let fileCounter = 0;
  //const uploadTask = theStorage.ref(`images/post/${postRecordId}/${postProfileId}/${theImage.name}`).put(theImage);
  const  uploadFunction =   (theImage,index,postRecordId) => {
    
    const uploadTask = firebase.storage().ref(`images/posts/${postRecordId}/${theImage.fileName}`).put(theImage.file);
       uploadTask.on(
          "state_changed",
          snapshot => {
            const progressCount = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //the theimage progress value is some how connected to state progress
            theImage.progress = progressCount;
            progress[index] = theImage.progress ;
            setProgress([...progress]);
      
          },
          error => {
            // Error function ...
            console.error(error);
          },
          () => {
            fileCounter++;
            setFileCountUpload(fileCounter);
          }
      );
    
  }


  var totalSizeUploaded = 0;
  const publishPost = () =>{

    if(isSending){
      return;
    }

    setAlertMessage("title");
    if(postTitle.trim().length ===0 || selectedLocation === null){
      setOpenSnack(true);
      return;
    }

    setIsSending(true);
    
    fileCounter =0;
     
    //var uniqueKey = firebase().database().ref().child('posts').push().key;
    totalSizeUploaded = 0;
    firestore.collection("posts").add({userId: globalThis.PROFILE_UID}).then( 
      (postRecord) =>{
        console.log('user profile save' + postRecord.id);   
        setRecordId(postRecord.id);
        return postRecord.id;
      }
    ).then((postRecordId)=>{
      //upload file
      console.log("upload started: "+ postRecordId);
      if(files.length>0){
        files.forEach((file,index) =>{
          totalSizeUploaded = totalSizeUploaded + file.size;
          progress.push(0);
          setProgress([...progress]);
          
          if(!file.overSize){
            uploadFunction(file,index,postRecordId);
          }

        });

      }else{
        //something bugy
        setFileCountUpload(1);
      } 
      return postRecordId;
    }).catch(function(error) {
      // Getting the Error details.
      var code = error.code;
      var message = error.message;
      var details = error.details;
      console.log("xxxxxxxxxxxxxxxxxxxcode: "+ message);
      console.error(error);
      // ...
    });

  }

  const  saveLink = async  () =>{
             
    let jscreencap =   firebase.functions().httpsCallable('jscreen_cap3');
      await jscreencap({ "url": urls[urls.length-1], "postRecordId": recordId})
        .then(function(result) {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data.text;
          console.log("sanitizedMessage: "+ sanitizedMessage);
          // ...
        }).catch(function(error) {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
     
          console.error(error);
          // ...
        });
         
  }

  const publishPostRecord = async ()=>{

    /** disable for now
    if(urls.length>0 ){ 
      var theLink  = urls[urls.length-1];
      if(theLink.indexOf("youtube") === -1 
          && theLink.indexOf("vimeo") === -1 
          && theLink.indexOf("youtu.be") === -1 ){
      await saveLink();
          }
    }
     */
   

    var _location = [];
    var pCountry = "";
    var pDistrict ="";
    var pCity = "";
    var pOther ="";
    var pLocation= "";
    var pCenter = [];

    if(selectedLocation !== null && selectedLocation !== undefined){
      _location = selectedLocation.name.split(',');
      if(_location.length>0)
        pCountry = _location.pop();
      if(_location.length>0){ 
        pDistrict = _location.pop();
        if(pDistrict.indexOf('City') !== -1){
          pCity = pDistrict;
          pDistrict = "";
        }
      }
      if(_location.length>0 && pCity.length===0){ 
        pCity = _location.pop();
      }
      if(_location.length>0)
        pOther = _location.pop();

      pLocation = selectedLocation.name ;
      
      pCenter = selectedLocation.center;
    }else{
      pLocation = props.userProfile.location;
      pCenter = props.userProfile.locationCenter;
      pCountry =  props.userProfile.locationCountry;
      pDistrict =  props.userProfile.locationDistrict;
      pCity =  props.userProfile.locationCity;
      pOther =  props.userProfile.locationOther;
    }
  
    
    
    const filesData = files.map((f) =>{return {fileName: f.fileName, author: f.author , size: f.size, fileType: f.fileType}});
    const pPrivacy = privacy === null? 'public' : privacy;
    const postedDate = utcTime();
    const post = {
      mainPostId: recordId,
      userId: globalThis.PROFILE_UID,
      location: pLocation, 
      locationCenter: pCenter,
      locationCountry: pCountry.trim(),
      locationDistrict: pDistrict.trim(),
      locationCity: pCity.trim(),
      locationOther: pOther.trim(),
      title: postTitle,
      story: postMessage,
      postType: 'post',
      countView: 1,
      countLike: 0,
      countDisLike: 0,
      countTag: 0,
      countShare: 0,
      countComment: 0,
      urlFiles: filesData,
      linkFiles: urls,
      follow: false,
      sharedType: pPrivacy,
      userName:  props.userProfile.name,
      hasUpdates: false,
      created: postedDate,
      updated: postedDate,
      parentPostId: "",
      parentUserId: "",
      parentUserName: ""

    }

    if(isNewPost){
      console.log('saving new post');
      //notes add a oncreate function to add parentPostId = post id
      // only runs on post type = "post"
      // other created post will get the postID from the UI when they comment the post
     

     await firestore.collection("posts").doc(recordId).set(post).then(()=>{
        setIsSending(false);
        props.handleCloseDialogPost("publishing...");
      }) ;
    
      setIsNewPost(false);  
      
    
    }else{
      //this will not be executed
      console.log('saving udpate post');
      console.log('user profile update' + recordId);  
      firestore.collection("posts").doc(recordId)
        .update( post ).then(()=>{
          console.log("updated........");
          //run timer
          //
        }).catch((error)=>{
          console.error(error);
        });
     }
  }
 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [messageBoxFocus, setMessageBoxFocus] = useState(false);
  
  const handleClickFeelings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFeelings = () => {
    setAnchorEl(null);
  };

  const handleSelectPrivacy = (level)=>{
     setPrivacy(level);
     handleCloseFeelings();
  }

  const handleMessageBox = () => {
    messageInput.current.focus();
  }

  
  return (
    
  <>
  
      <Dialog
        fullScreen={props.matchesXS}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.openPostDialog}
        onClose={checkClosingDialog}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Transition}
        className={classes.postDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
          
        <DialogTitle id="max-width-dialog-title"> 
          
              <Card >
                  <CardHeader
                      avatar={

                            <Avatar alt="username" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${globalThis.PROFILE_UID}?alt=media`}  className={classes.avatar} />    
                      }
                      action={
                        <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                            {  privacy&&privacy==='public'?  <PublicIcon color="primary" fontSize="small" /> 
                                    : privacy==='friend'?  <PersonIcon color="primary"  fontSize="small" />
                                    : privacy==='shared'?  <PeopleIcon  color="primary" fontSize="small" />
                                    : privacy==='private'?  <LockIcon  color="primary" fontSize="small" />:<></>   
                            }
                            <IconButton aria-label="close"   onClick={checkClosingDialog}>
                              <CloseIcon color="primary"/>
                            </IconButton>
                            </Grid>
                        }
                       
                      title={props.userProfile.name}
                      subheader={today}
                  />
              </Card>
       
        </DialogTitle>
        <DialogContent>
 
          <Card>
            <div  {...getRootProps()}>
            <CardMedia>
               <PostingImage tileData={files} linkFiles={urls}/>   
              
            </CardMedia>
            </div>
            <input {...getInputProps()} /> 
             
            <TextField
                  id="postTitle-textarea"
                  label= "Title"
                  placeholder="Share your thought."
                  variant="outlined"
                  fullWidth
                  defaultValue = {postTitle}
                  color="primary"
                  size="medium"
                  onChange={handlePostTitle}
                  inputRef = {messageInput}
                  className ={classes.textLocationField}
                />
                
                {selectedLocation &&
                <TextField
                  id="location-textarea"
                  label="Where are you?"
                  placeholder="Location"
                  multiline
                  variant="outlined"
                  fullWidth
                  value = {selectedLocation.name}
                  color="primary"
                  rowsMax={1}
                  size="medium"
                  InputProps={{ readOnly: true,}} 
                  className ={classes.textLocationField}
                />
                }   

                {urls.length>0 &&
                  <TextField
                    id="urls-textarea"
                    label="Link"
                    placeholder="url link"
                    variant="outlined"
                    fullWidth
                    value = {urls.length>0&&urls[urls.length-1]}
                    color="primary"
                    rowsMax={1}
                    size="medium"
                    InputProps={{ readOnly: true,}} 
                    className ={classes.textLocationField}
                  />
                }   

                <TextField
                  id="postMessage-textarea"
                  label= "Message"
                  placeholder="Share your thought."
                  multiline
                  variant="outlined"
                  fullWidth
                  defaultValue = {postMessage}
                  color="primary"
                  rowsMax={8}
                  size="medium"
                  onChange={handlePostMessage}
                  className ={classes.textLocationField}
                />
             
             </Card>
           
        </DialogContent>
        <DialogActions>
 
              <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                  
                      <Grid item xs={12} md={6} sm={6} container direction="row"   justify="flex-start">
                  
                          <Grid item xs={3} sm={2} md={2}>

                            <Tooltip title="Set your privacy level.">
                                <IconButton aria-label="Privacy"
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                color={privacy?'primary':'secondary'}
                                onClick={handleClickFeelings}>
                                    <PeopleIcon />
                                </IconButton>
                              </Tooltip>
        
                                <Menu
                                    elevation={0}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                    id="customized-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseFeelings}
                                  >
                                  
                                    <MenuItem   className={clsx({ [classes.menuItemSelected]: privacy==='public', })} 
                                        onClick={()=>{handleSelectPrivacy("public")}}>
                                      <ListItemIcon>
                                        <PublicIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText primary="Public" />
                                    </MenuItem>
                                    <MenuItem  className={clsx({ [classes.menuItemSelected]: privacy==='friend', })} onClick={()=>{handleSelectPrivacy("friend")}}>
                                      <ListItemIcon>
                                        <PersonIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText primary="Friend" />
                                    </MenuItem>
                                    <MenuItem className={clsx({ [classes.menuItemSelected]: privacy==='shared', })} onClick={()=>{handleSelectPrivacy("shared")}}>
                                      <ListItemIcon>
                                        <PeopleIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText primary="Friend Of Friends" />
                                    </MenuItem>
                                    <MenuItem className={clsx({ [classes.menuItemSelected]: privacy==='private', })} onClick={()=>{handleSelectPrivacy("private")}}>
                                      <ListItemIcon>
                                        <LockIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText primary="Private" />
                                    </MenuItem>
                                    
                                  </Menu>
                                
                          </Grid>
                          <Grid item xs={3} sm={2} md={2}>
                          
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                onChange={handleFileChange}
                                multiple
                                type="file"
                              />
                              <label htmlFor="contained-button-file">
                              <Tooltip title="Upload images, limit 2mb size.">
                                <IconButton aria-label="upload video/image" component="span">
                                <PhotoLibraryIcon color={files.length>0?'primary':'secondary'}/>
                                </IconButton> 
                                </Tooltip>
                              </label>
                          </Grid>
                          
                          <Grid item xs={3} sm={2} md={2}>
                              <Tooltip title="Select the location of this post.">
                              <IconButton aria-label="current location" onClick={ () => handleMapDialog("posting",selectedLocation) } > 
                                <MyLocationIcon  color={selectedLocation===null?'secondary':'primary'}/>                       
                              </IconButton>  
                            </Tooltip>
          
                          </Grid>

                          <Grid item xs={3} sm={2} md={2}>
                            <Tooltip title="You must enter the title and the story.">
                                  <IconButton aria-label="message text" onClick={handleMessageBox} > 
                                    <MessageIcon  color={postTitle.length>0?'primary':'secondary'}/>                       
                                  </IconButton>  
                            </Tooltip>
          
                          </Grid>
                      
                      </Grid>  
                      <Grid item xs={12} md={6} sm={6} container direction="row" justify="center"  alignItems="center">
                             
                             <Button
                                 variant="outlined"
                                 color="primary"
                                 className={classes.button}
                                 onClick={publishPost}
                                 fullWidth
                               >
                                   {isSending && <CircularProgress/>  }
                                   {!isSending && <><PublishIcon/> Publish </>}
                               </Button>
                     </Grid>
                      
            
              </Grid>
        </DialogActions>
        <Snackbar open={openSnack} autoHideDuration={5000} onClose={handleCloseSnack} >
            
            {alertMessage==='size'?
                <Alert  onClose={handleCloseSnack} severity="warning">
                    <AlertTitle> Uploaded Files                                  
                    </AlertTitle>
                    
                    {overSizeMessage}
                </Alert>
                :
                <Alert  onClose={handleCloseSnack} severity="warning">
                    <AlertTitle> You have not enter the title or the location of the post. For better search result, select a location. Just select nearest city or district.  
                    <MyLocationIcon  color='primary'/>  
                    </AlertTitle>
                    
                </Alert>
            }
        </Snackbar>
      </Dialog>
     
      <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapCloseDialog} source="posting" /> 
     
      </>
  
  );
}

 
export default Posting;

//The Bootstrap grid system has four classes: xs (phones), sm (tablets), md (desktops), and lg (larger desktops). The classes can be combined to create more dynamic and flexible layouts.