import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import { List, Grid, Typography, ListItem, ListItemAvatar, Badge, Avatar, ListItemText, ListItemSecondaryAction, IconButton, TextField, Container, Divider } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { utcTimePlus } from '../utils/MantawiTime';
import AddIcon from '@material-ui/icons/Add'; 
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Checkbox from '@material-ui/core/Checkbox';
import green from '@material-ui/core/colors/green';
import CheckIcon from '@material-ui/icons/Check';
import {utcTime,utcTimeOrg} from '../utils/MantawiTime'; 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({    
    buttonUi: {
      textTransform: "none",
      padding: theme.spacing(1),
      width: 100
    } ,
    listItemUi:{
      width: "100%",
    
    }, 
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
  
    },
    divider: {
      height: 28,
      margin: 4,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    gridUi:{
      marginBottom: '20px',
    },
    checked:{
      color: 'green',
     
    }
  }),
);
   
const GreenCheckbox = withStyles({
  root: {
    color: "000000",
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function AddFriendDialog({open, userId, handleClose}) {
   
  const classes = useStyles();
  const [myFriends, setMyFriends] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const checkColor = green[500]; 

  const searchUser = ()=>{
    console.log("search user");
  }

  const whatColor = (status: string): string =>{
    var color = "primary";
    if(status==="request"){
      color = "secondary"
    }
    
    return color;
  }


  useEffect( ()=>{ 
    console.log("start users");
    setIsLoading(true);
    firestore.collection("friends").where("userId", "==",  userId ).where("status","==","friend")
    .get().then( async function(querySnapshot) {
      console.log("getting friends");
          querySnapshot.forEach( await function(doc) {
            
              console.log(doc.id, " => ", doc.data());
              myFriends.push(doc.data().friendId);
          });
          myFriends.push(userId);
          setMyFriends(myFriends);
    })
    .catch(function(error) {
        console.log("Error getting friends documents: ", error);
        console.error(error);
    });

    firestore.collection("users").get().then( async function(querySnapshot) {
         console.log("getting users");
         var mfriends =   querySnapshot.docs.map(doc => ({
          _id: doc.id,
          selected: false,
          ...(doc.data() ),
        }));
        
          setUsers( mfriends.filter( function (data) { return myFriends.indexOf(data. _id) === -1}) );
    })
    .catch(function(error) {
        console.log("Error getting friends documents: ", error);
        console.error(error);
    });
    setIsLoading(false);

  },[] )

  const handleClickItem = (user) =>{
    user.selected = !user.selected;
    setUsers([...users]);
    console.log(user.selected);
  }

  const handleRequest = () =>{

    const _datePost = utcTime();
    var batch = firestore.batch();

    users.map((user) =>{ 
       console.log(user.name + "=" + user.selected) 
       if(user.selected){
        var key = `${userId}${user._id}`;
        var _friend= {
          userId: userId,
          friendId: user._id,
          friendName: user.name,
          userName: globalThis.PROFILE_USERNAME,
          status: 'request',  //friend, block, request, denied
          hasUpdates: true,
          created: _datePost,
          updated: _datePost,
        }
        batch.set(firestore.collection("friends").doc(key), _friend);
      }
 
    });
    batch.commit();
    handleClose();
   
  }
 
  return (
    <div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth 
        maxWidth={'sm'}
      >
        <DialogTitle id="alert-dialog-title">Add New Friends</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Send Friend, request?
          </DialogContentText>
          <Grid container className={classes.gridUi} direction="row" justify="center" alignItems="center" spacing={1}>  
              
              <Grid item xs>
                 <TextField variant="outlined" fullWidth 
                  id="search-textarea"
                  label= ""
                  placeholder="Searching is disabled for now."
                  size="small"
                  disabled
                 ></TextField>
              </Grid>
             <Grid item >     
          
             <Button
                 variant="outlined"
                 color="primary"
                 className={classes.buttonUi}
                disabled
                 onClick={searchUser}
               >
                 <Typography className={classes.heading}>Search</Typography> 
             </Button>
             </Grid>
             
           </Grid>
    <Container>
    
          {!isLoading && 
    <List className={classes.listUi} >
          
          {  users.length === 0 && <Typography className={classes.heading}>Congratulation no more friends to add.</Typography> }
        {  users.map((user) =>(
        
          <ListItem  className={classes.listItemUi} key={user._id} button  onClick={ ()=>{handleClickItem(user)}}>

             <ListItemAvatar>
             <Badge color="secondary" variant="dot">
                 <Avatar  alt={`${user._id}`}
                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${user._id}?alt=media`}>
                      {user.name}
                  </Avatar>
                  </Badge>
              </ListItemAvatar>
           
         
                <ListItemText primary={ <Typography 
                                    variant="subtitle2"
                                    display="block"
                                    color="textPrimary"
                                    noWrap >
                                  {user.name}
                              </Typography>} 
                 />
             
              <ListItemSecondaryAction>

             {user.selected&& <CheckIcon />}
             {/**
                  <GreenCheckbox
                    edge="end"   
                    checked={user.selected}      
                  />
                   */}
            </ListItemSecondaryAction>
           
          </ListItem>
            
        ))}
      </List>
    }
  </Container>
        </DialogContent>
       
        <DialogActions>
        <Divider className={classes.divider}  />
          <Button className={classes.buttonUi} variant="outlined" onClick={handleRequest} color="primary">
            Request
          </Button>
          <Button  className={classes.buttonUi} variant="outlined" onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}