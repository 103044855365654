import React, { useState, useEffect, useContext, Fragment } from 'react';
 
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {ContactUs} from '../utils/mantawiObject';
 
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CancelIcon from '@material-ui/icons/Cancel';
 
import { Snackbar, IconButton, Grid, Paper, LinearProgress, MenuItem, InputLabel} from '@material-ui/core';
import { Link  } from 'gatsby-theme-material-ui';
import { navigate } from "gatsby"
import { utcTime } from '../utils/MantawiTime';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    rootEntry: {
      display: 'flex',
      flexGrow: 1,
      marginTop: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    expandButton: {    
      minWidth: '100px'
    },
    contactUi:{
      width: '90%',
      height: '100vh'
    },
    cardUi:{
      width: '90%',
      height: 500,
    },
    snackTop:{
      marginTop: 50,
    },
   
  }),
);

 

const ContactUsForm = ({setShowPage}) => {
    const classes = useStyles();
   
  
    const [contactUs, setContactUs] = useState<ContactUs>( {
        name: "",
        email: "",
        message: "",
        topic: "",
        notes: "",
        created: null,
        updated: null,
    });
  
    
    const [loading, setLoading] = useState( false );
    const [errorMsg, setError] = useState(null);
    const [image, setImage] = useState(null);
    const [displayName,setDisplayName] = useState("");
    const { isLoggedIn, profile }  = useAuth();
    const [open, setOpen] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [severity, setSeverity] = React.useState("warning");
    const [message, setMessage] = React.useState("");

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        setOpen(false);
        if(severity==="success"){
          handleCancel();
        }
        return;
      }
  
   
    };
 
    useEffect(()=>{
 
 
      if(profile){
   
        console.log("mount useefffect profile: " +profile.uid );
        
        firestore.collection("users").doc(profile.uid).get().then(
          function(doc){

            if (doc.exists) {
              var data = doc.data();
              setDisplayName(data.name);
              setContactUs({
                  ...contactUs, 
                  email: data.email,
                  name: data.name,
                  topic: "Others",
                  message: "",
              });
     
            } else {

              console.log("this happened.....")
              setContactUs({
                name:  "",
                email: "",
                topic: "Others",
                message: "",
              })
   
            }
          }

        ).catch( (error)=> {
          console.error(error);
        }); 
   
      }else{
        setDisplayName('fetching...');
      }
      console.log("mount useefffect account");
   

    },[profile]);

    const saveContactUs =  () => {  
      console.log("saveUserProfile");
      console.log(JSON.stringify(contactUs));
      console.log(globalThis.PROFILE_UID);
      var today = utcTime();
      setIsSending(true);
      if(contactUs.name.trim().length === 0 || contactUs.email.trim().length === 0  || contactUs.message.trim().length === 0 ){
        setSeverity("warning");
        setMessage("All fields are required.")
        setOpen(true);
        setIsSending(false);
      }else if(contactUs.name.trim().length !== 0 && contactUs.email.trim().length !== 0  && contactUs.message.trim().length !== 0 ){
            var _contactUs = 
              {
                name: contactUs.name,
                email: contactUs.email,
                message: contactUs.message,
                topic: contactUs.topic,
                status: 'open',
                sendEmail: false,
                created: today,
                updated: today, 
              }

          
            firestore.collection("contactus").add(_contactUs).then((doc)=>{
                console.log("updated........");
              }).then(()=>{
                setSeverity("success");
                setMessage("Message send.")
                setOpen(true);
              }).finally(()=>{
                setIsSending(false)
              });
      }
          
    }  

    const handleInputChange = (event) =>{  
        const value = event.target.value;
        const name = event.target.name;     
    
        setContactUs(
          {...contactUs,
          [name]: value}
        );
          
    }
 
    const handleChangeTopic = (event: React.ChangeEvent<{ value: unknown }>) => {
     
      setContactUs(
        {...contactUs,
        topic: event.target.value as string}
      );
    };

    const handleCancel=()=>{
      navigate("/")
       setShowPage(2) 
    }
    const spacing = 2;
 
  
    return (   
      <Container className={classes.contactUi} >
        <Card className={classes.cardUi} raised>     
              <CardContent>
              <form noValidate autoComplete="off">
                <Grid container  direction="column" >
                      
                     <Grid item xs={12}>
                        <h2>
                          Contact US   {isLoggedIn && `-${displayName}`}
                        </h2> 
                     </Grid>
                  
                     <Grid item key={'textfields1'} xs={12}>
                        <TextField   className={classes.rootEntry} id="name" name="name" label="Name" value={contactUs.name}  onChange={handleInputChange}/>
                     </Grid>

                    <Grid item key={'textfields2'} xs={12}>
                        <TextField className={classes.rootEntry} type="email" id="email" name="email" label="Email"   value={contactUs.email}  onChange={handleInputChange}/>
                    </Grid>

                    <Grid item key={'textfields3'} xs={12}>
                    <TextField select className={classes.rootEntry}   id="topic" name="topic" label="Topic"   value={contactUs.topic}  onChange={handleChangeTopic}>
                          <MenuItem value={'Others'}>Others</MenuItem>
                          <MenuItem value={'Adds'}>Adds</MenuItem>
                          <MenuItem value={'Help'}>Help</MenuItem>
                    </TextField>
                    {/**
                      <Select  className={classes.rootEntry}
                          labelId="topic-select-label"
                          id="topic-select"
                          value={contactUs.topic}
                          onChange={handleChangeTopic}
                          label="Topic"
                        >
                          <MenuItem value={'Others'}>Others</MenuItem>
                          <MenuItem value={'Adds'}>Adds</MenuItem>
                          <MenuItem value={'Help'}>Help</MenuItem>
                        </Select>
                         */}
                    </Grid>


                    <Grid item key={'textfields4'} xs={12}>
                        <TextField  multiline  rows={4} className={classes.rootEntry} id="message" name="message" label="Message"   value={contactUs.message}  onChange={handleInputChange}/>
                    </Grid>
                 
                 </Grid>  
                 </form>
              </CardContent>
              <CardActions disableSpacing >
                <Grid container className={classes.root}  justify="flex-end">
                    <Grid item>
                    {isSending && <CircularProgress/>  }
                    {!isSending &&   
                     <Button className={classes.expandButton} variant="outlined" color="primary" onClick={saveContactUs }>
                       Submit
                     </Button>}
                
                  </Grid>
                  <Grid item>
                  <Button className={classes.expandButton} variant="outlined" color="primary" onClick={handleCancel}>
                       Go Back
                  </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            <Snackbar className={classes.snackTop} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open}   onClose={handleClose} >  
              <Alert  onClose={handleClose}  severity={severity}>
              <AlertTitle>{severity}</AlertTitle>
              <strong>{message}</strong>
               </Alert>
            </Snackbar>
      
      </Container>
      
    )
}

export default ContactUsForm;
