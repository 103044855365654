import React , {useState, useEffect, Fragment, useRef} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
import { navigate } from "gatsby"
import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, List, ListItem, ListItemAvatar, ListItemText, Divider, Grid, Container, TextField, Menu, MenuItem, CircularProgress, Icon, ListItemIcon, Link } from '@material-ui/core';
import Comments from './Comments';
import CommentEntry from './CommentEntry';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {utcTime,utcTimeOrg} from '../utils/MantawiTime'; 
import { OpenSourceInitiative, FireHydrantAlert } from 'mdi-material-ui';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import green from "@material-ui/core/colors/green";
import PostFullView from './PostFullView';
import SEO from '../components/SEO';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import ConfirmShareDialog from '../templates/ConfirmShareDialog';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
//const useStyles = ( props ) => makeStyles((theme: Theme) =>
const useStyles =  makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 345,
      minHeight: 320,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    
    button: {
      textTransform: "none"
    },
    iconUi:{
        width:'100%',
        marginTop: -6
    },
    buttonPublish:{
      textTransform: "none",
      marginTop: '10px'
    },
    buttonPublish2:{
      textTransform: "none",
   
    },
    cardContent:{
      oveflow: "auto",
    },
    actionsUi1:{
      
      marginTop: '-15px',
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
      width: "50%"
    },
    actionsUi2:{
   
      marginTop: '-15px',
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
      float: "right",
      width: "50%"
    },
    cardActionsUi:{
      width: "100%"
    },
   gridUi:{
    
    marginTop: '-15px'
   },
   customBadge: {
    backgroundColor: "green",
    color: "white"
  },
    menuItemUi:{
      width: 'auto'
    },
    titleUi:{
      padding: 10,
  
    } ,
    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }, 
    adds:{
      width: 330,
      height: 80
    },
    storyUi:{
      overflow: 'scroll'
    },
    avatarParentUi:{
      marginTop: -8,
    },
    avatarChildUi:{
      marginLeft: -10,
    },
    sourceUi:{
      paddingLeft: 10,
    }
  
  }),
);
 

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      
      right: -20,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    }, 
  }),
)(Badge);

 
TimeAgo.addLocale(en);

function linkify_o(text) {
  var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
}

function createInnerHtml(key, story){
  var ht = linkify_o(story);
  document.getElementById(key).innerHTML =ht;
}
  

const Post = ({post,tagPost,friend, colCount, index}) => {

//  console.log("tagPost: " + tagPost +  "=" + index);
 //const classes = useStyles( {rootHeight: colCount ===12? 0: 300} )(); 
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [ postDialogView, setPostDialogView] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [showMore, setShowMore] = useState(0);
  const myRef = useRef(null);

  function copyToClipboard(theLink) {
   // checkView();
    const el = document.createElement('textarea');
    el.value = theLink;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  function textSlice(text, count, insertDots){
    if(insertDots){ 
      text.length > count? setIsMore(true) :setIsMore(false);
    }
    return text.slice(0, count) ;
  }

  const hanldeShowMore = () =>{
     if(showMore === 0){
      setShowMore(1);
     }else{
      setShowMore(0);
     }
   
   
  }

  // const scrollToRef = (ref) =>{
  //   ref.current.scrollIntoView();
  //   //window.scrollTo(0, ref.current.offsetTop)   
  // } 

  const handleExpandClick = () => {
   
    setExpanded(!expanded);
  };

  const handlePostDialogView =(status: boolean) =>{
    //tomorrow move it to the index.
   // setPostDialogView(status);
  }

  const timeAgo = new TimeAgo('en-US');
  const [newComment, setNewComment] = useState(null);
  const [editStory, setEditStory] = useState(true);
  const [isSavingFriend, setIsSavingFriend] = useState(false);
  
  // const findUserPostTag = () =>{
  //     var isFound = false;
  //     return tagPosts.find(e => e._id === postAndUserId );
  // }

  // const [tagPost, setTagPost] = useState(null);
  //media 
  //https://stackoverflow.com/questions/39188405/how-to-use-video-html-tag-instead-of-img-tag-in-material-ui-card-component
  

  const handleActionClick = (optType) =>{
   // console.log(optType);
   
    var key = post._id+globalThis.PROFILE_UID;
    if(tagPost){
      //most updates happens only to like, dislike, tag
      if(optType ==='like' || optType ==='disLike' || optType ==='tag' || optType ==='shared' ){
          var isLike = false;
          var isDisLike = false;
          var isTag = false;
          var isShared = false;
          var tagStatus = false;
          var isLikeAndDisLikeEqual = tagPost.like === tagPost.disLike;
          if(optType === 'like' && !tagPost.like){
              tagPost.like = true;  
              tagPost.disLike = false; 
              isLike = true;
          }
          if(optType === 'disLike' && !tagPost.disLike){
              tagPost.disLike = true;
              tagPost.like = false; 
              isDisLike= true;
          }
          if(optType === 'tag'){
              tagPost.tag = !tagPost.tag;
              tagStatus = tagPost.tag ;
              isTag= true;
          }

          if( optType ==='shared'){
            tagPost.shared = !tagPost.shared;
            tagStatus = tagPost.shared ;
            isShared= true;
          }
         
          tagPost.updated = utcTime();
          

          firestore.collection("tags").doc(key)
          .update( tagPost ).then(()=>{
            console.log("tag post updated........");
         
          }).then(()=>{
            if(isLike || isDisLike || isTag || isShared){
             firestore.collection("posts").doc(post._id).get().then(
              (doc) =>{
                if(doc.exists){
                   var data = doc.data();
                   if(isLike){
                      data.countLike = data.countLike + 1;
                      if(data.countDisLike > 0 && !isLikeAndDisLikeEqual){
                       data.countDisLike = data.countDisLike - 1;
                      }
                   } 
                   if(isDisLike){
                     if(data.countLike > 0 && !isLikeAndDisLikeEqual){ 
                       data.countLike = data.countLike - 1;
                     }
                      data.countDisLike = data.countDisLike + 1;
                   } 
                   if(isTag){
                      if(tagStatus){
                        data.countTag = data.countTag +1;
                      }else{
                        if(data.countTag > 0){
                          data.countTag = data.countTag -1;
                        }
                      }
                   }
                   if(isShared){
                     
                      if(tagStatus){
                        data.countShare = data.countShare +1;
                      } 
                   }

                   firestore.collection("posts").doc(post._id)
                   .update( data ).then(()=>{
                     console.log("post updated........");
                  
                   }).catch( (error)=>{
                    console.error(error);
                  });

                }
              })
            }
          }).catch( (error)=>{
            console.error(error);
          });;
 
      }

    }else{
      //does not exists creat a new one
      var createdTime = utcTime();
      
      var _tagPost = {
        postId: post._id,
        authorId: post.userId,
        authorName: post.userName,
        userId: globalThis.PROFILE_UID,
        story: post.title,
        view: true,
        tag: optType==='tag'?true:false,
        like: optType==='like'?true:false,
        disLike: optType==='disLike'?true:false,
        shared: optType==='shared'?true:false,
        hasUpdates: false,
        created: createdTime,
        updated: createdTime,
      }
 
      firestore.collection('tags').doc(key).set(_tagPost)
          .then( (doc)=>{
            //  tagPost = doc;
          }).catch( (error)=>{
            console.error(error);
          });;
    }
    
    console.log("tagPost: " + tagPost);
  }

  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSending,setIsSending] = useState(false);
  const [postMessage, setPostMessage] = useState("");

  const publishPostRecord = ()=>{
    console.log("update text post: " + post._id);
    setIsSending(true);
    const postedDate = utcTime();
    const message = postMessage;
    firestore.collection("posts").doc(post._id)
      .update( {updated: postedDate, story: message, hasUpdates: true, sharedType: privacy} ).then(()=>{
        console.log("updated........");
        setEditStory(true);
        setShowMore(0);
        setIsSending(false);
      }).catch( (error)=>{
        console.error(error);
      });;
  }

  const cancelPostRecord =() =>{
    setEditStory(true);
    setShowMore(0);
    setIsSending(false);
  }
  
  const handleClickMenu = (event) => {
   
    setAnchorEl(event.currentTarget);
    //checkView();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenu =(stype) =>{
    setAnchorEl(null);
    if(stype==='edit'){
      setEditStory(false);
      setShowMore(-1);
    }

    if(stype==='addFriend'){
      setIsSavingFriend(true);
      const _datePost = utcTime();
      
      var _friend= {
        userId: globalThis.PROFILE_UID,
        friendId: post.userId,
        friendName: post.userName,
        userName: globalThis.PROFILE_USERNAME,
        status: 'request',  //friend, block, request, denied
        hasUpdates: true,
        created: _datePost,
        updated: _datePost,
      }

      var key = `${globalThis.PROFILE_UID}${post.userId}`;
    
      firestore.collection("friends").doc(key).set(_friend).then(
        () =>{
          console.log('friends added: ' + `${globalThis.PROFILE_UID} == ${post.userId}` );   
          setIsSavingFriend(false);
        }
      ).catch( (error)=>{
        console.error(error);
      });
    }

    if(stype==='delete'){
       
        firestore.collection("posts").doc(post._id).delete().then(
          () =>{
            console.log('posted delete: ' + `${post.userId}` );   
          }
        ).catch( (error)=>{
          console.error('posted delete error: ' + error);
        });
    }

    console.log(stype)
  }
  const handlePostMessage = (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     

    setPostMessage(value );
  }

  const [confirmSharedDialog, setconfirmSharedDialog] = useState(false);

  const handleCloseConfirmShareDialog =() =>{
    setconfirmSharedDialog(false);
  }

  const handleOpenConfirmShareDialog = () =>{
    console.log("handleOpenConfirmShareDialog:");
    var openConfirm = false;
    if((tagPost === undefined || tagPost === null)){
      openConfirm = true;
    } else{
      openConfirm = !tagPost.shared;
    }

    if(post.userId !== globalThis.PROFILE_UID &&  openConfirm ){
      setconfirmSharedDialog(true);
    }

   
  }

  const handleConfirmShareDialog = () =>{

    console.log('handleConfirmShareDialog'  ); 
    //share here
    var newpost = {...post }
    delete newpost._id;
    const postedDate = utcTime();
    newpost.parentPostId = post._id;
    newpost.parentUserId = post.userId;
    newpost.parentUserName= post.userName;
    newpost.userId = globalThis.PROFILE_UID;
    newpost.userName = globalThis.PROFILE_USERNAME;
    newpost.created =  postedDate;
    newpost.updated =  postedDate;
  
    console.log(JSON.stringify(newpost));
    console.log("==================");
    console.log(JSON.stringify(post));

    firestore.collection("posts").add(newpost).then( 
      (postRecord) =>{
        console.log('post shared' + postRecord.id);  
        handleActionClick('shared') ;
      }
    ).catch( (error)=>{
      console.error(error);
    });

    setconfirmSharedDialog(false);
  }

  const [privacy, setPrivacy] = useState(post.sharedType);
  const [anchorElP, setAnchorElP] = React.useState<null | HTMLElement>(null);
   
  const handleClickFeelings = (event: React.MouseEvent<HTMLElement>) => {
    if( !editStory){
    
      setAnchorElP(event.currentTarget);
    }
  
  };

  const handleCloseFeelings = () => {
    setAnchorElP(null);
  };

  const handleSelectPrivacy = (level)=>{
     setPrivacy(level);
     handleCloseFeelings();
  }

  const handleUrlLink =() =>{
   
    if( post.linkFiles === undefined){

    }else if( post.linkFiles.length > 0){
         console.log("post.linkfiles");
      
    }
 

  }
 
  const checkView =() =>{
    if(tagPost === null || tagPost === undefined){
      firestore.collection("posts").doc(post._id).get().then(
        (doc) =>{
          if(doc.exists){
             var data = doc.data();
             var count  = data.countView + 1;
             firestore.collection("posts").doc(post._id)
             .update( {countView : count} ).then(()=>{
               console.log("post view count updated........");
            
             }).catch( (error)=>{
              console.error(error);
            });

          }
        })

    }
  }
  const preventDefault = (event) =>{ 
    event.preventDefault();
    checkView();
  }

  useEffect(()=>{
    if(showMore === 1){
      createInnerHtml("TpostStory"+post._id , post.story );
   
    }
    if (showMore === 0){
      createInnerHtml("FpostStory"+post._id , textSlice(post.story, 500 , false) );
    }
  //  createInnerHtml("postStory"+post._id , textSlice(post.story, 500 , true) );
  //  createInnerHtml("postStory"+post._id ,  post.story );
  },[ showMore]) 

  const [psHeight, setPsHeight] = useState(300);
  // useEffect(()=>{
  //   if(colCount === 12){
  //     setPsHeight(0);
  //   }else{
  //     setPsHeight(300);
  //   }
  // },[ colCount]) 

  const postSharedType = (sharedType)=>{
    if( sharedType==='public' ) {
      return 'Public'
    }else if(  sharedType==='friend' ){
      return 'Friend'
    }
    else if( sharedType==='shared' ){
      return 'Shared'
    }
    else if(  sharedType==='private' ){
      return 'Private'
    }
  }

  const isRSSFeed = (_name)=>{
    let _isRss = false;

    if(_name==="MANTAWI news"){
      _isRss = true;
    }else if(_name === "ASIAN reports"){
      _isRss = true;
    }

    return _isRss;
  }
  
  return (
    <Card ref={myRef}  className={classes.root} raised>
          <SEO title={post.title}>
          
           <meta
            name="description"
            content={`${post.title}`}
          />
           <meta
            name="url"
            content={`${process.env.GATSBY_HOST}?fid=${post._id}&where=mainPostId&pr=${post.sharedType.substr(0, 2)}`}
          />
        </SEO>
      <CardHeader
        avatar={
          (post.parentPostId && post.parentPostId.length > 0)?
          <Grid container  justify="center" alignItems="center">
            <Grid item className={classes.avatarParentUi} >
            <Link href={`${process.env.GATSBY_HOST}?fid=${post.parentPostId}&where=mainPostId&pr=${post.sharedType.substr(0, 2)}`} target="_blank" rel="noopener"  >
              <Avatar aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${post.parentUserId}?alt=media`}  className={classes.avatar} >
              {post.parentUserName}
              </Avatar>
              </Link>
            </Grid>
            <Grid item className={classes.avatarChildUi}>
              <Avatar aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${post.userId}?alt=media`}  className={classes.avatar}>
              {post.userName}
              </Avatar>
            </Grid>
          </Grid>
          :
          <Avatar aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${post.userId}?alt=media`}  className={classes.avatar}>
              {post.userName}
              </Avatar>
        }
        action={
           <>
           {/** nice, this is working
            <IconButton onClick={ (event) => handleClickFeelings(event, post)}></IconButton>
             */}

            <Tooltip title={ `${postSharedType(post.sharedType)}`  }>
              <IconButton onClick={handleClickFeelings}>
              {   post.sharedType==='public' &&  <PublicIcon   color="primary" fontSize="small" />  }
                {   post.sharedType==='friend' && <PersonIcon color="primary"  fontSize="small" />  }
              {   post.sharedType==='shared' && <PeopleIcon color="primary" fontSize="small" /> } 
                {   post.sharedType==='private' &&  <LockIcon  color="primary" fontSize="small" /> }
              
              </IconButton>
           </Tooltip>

          <IconButton aria-label="settings" aria-controls="setting-menu" aria-haspopup="true" onClick={handleClickMenu}>
            <MoreVertIcon />
          </IconButton>
          </>
      
        }
        title= {post.userName} 
        subheader={`${timeAgo.format(utcTimeOrg(post.updated),'twitter')} : ${post.locationCountry},${post.locationDistrict}${post.locationCity?(','+post.locationCity):''}`} 

      />
       <Menu
        id="setting-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        
            { post.userId === globalThis.PROFILE_UID?<Container>
            {editStory?<MenuItem className={classes.menuItemUi} onClick={() => {handleMenu('edit')}}>Edit</MenuItem>
            :
            <MenuItem className={classes.menuItemUi} onClick={cancelPostRecord}>Cancel</MenuItem>
             }
            <MenuItem className={classes.menuItemUi} onClick={() => {handleMenu('delete')}}>Delete</MenuItem>
            </Container>:""
            } 
            <Container>
            {post.sharedType==="shared"&& <MenuItem className={classes.menuItemUi} onClick={handleOpenConfirmShareDialog}>Share</MenuItem>}
            {post.sharedType =="friend"&& <MenuItem color="primary" className={classes.menuItemUi}>Friends Only Post</MenuItem>}
           
            
            {
              isSavingFriend?<MenuItem className={classes.menuItemUi} ><CircularProgress/> </MenuItem>:
              friend?(friend.status==='request'?<MenuItem className={classes.menuItemUi} >Friend Request</MenuItem>
                    :friend.status==='denied'?<MenuItem className={classes.menuItemUi} onClick={() => {handleMenu('addFriend')}}>Add (Friend)</MenuItem>
                    :friend.status==='friend'?<MenuItem className={classes.menuItemUi} >Friend</MenuItem>
                    :"")
                    :post.userId!==globalThis.PROFILE_UID?<MenuItem className={classes.menuItemUi} onClick={() => {handleMenu('addFriend')}}>Add Friend</MenuItem>:""
            }
             
            
            </Container>
        </Menu>

        <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                id="customized-menu"
                anchorEl={anchorElP}
                keepMounted
                open={Boolean(anchorElP)}
                onClose={handleCloseFeelings}
              >
                             
                  <MenuItem   className={clsx({ [classes.menuItemSelected]: privacy==='public', })} 
                      onClick={()=>{handleSelectPrivacy("public")}}>
                    <ListItemIcon>
                      <PublicIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Public" />
                  </MenuItem>
                  <MenuItem  className={clsx({ [classes.menuItemSelected]: privacy==='friend', })} onClick={()=>{handleSelectPrivacy("friend")}}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Friend" />
                  </MenuItem>
                  <MenuItem className={clsx({ [classes.menuItemSelected]: privacy==='shared', })} onClick={()=>{handleSelectPrivacy("shared")}}>
                    <ListItemIcon>
                      <PeopleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Friend Of Friends" />
                  </MenuItem>
                  <MenuItem className={clsx({ [classes.menuItemSelected]: privacy==='private', })} onClick={()=>{handleSelectPrivacy("private")}}>
                    <ListItemIcon>
                      <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Private" />
                  </MenuItem>
                  
                </Menu>        

        <Typography
            className={classes.titleUi}
            id="title-textarea"
            variant="h6"
        >
        {textSlice(post.title, 150 , false) }
        </Typography>
        <PostImage tileData={post.urlFiles} mainPostId={post.mainPostId} linkFiles={post.linkFiles}  psHeight={psHeight} source={'post'}  isRssFeed={isRSSFeed(post.userName)}/> 
        { (post.linkFiles.length> 0) && 
            <Link className={classes.sourceUi} href={post.linkFiles[post.linkFiles.length-1]} target="_blank" rel="noopener"  >
              Source Link
            </Link>
        }
 
    
      <CardContent>


      <Grid container>
        <Collapse in={showMore === 0} timeout="auto" unmountOnExit>
        <Typography  id={"FpostStory"+post._id} variant="body1"  align="justify"   display="block"  color="textPrimary" />  
        </Collapse>
        <Collapse in={showMore === 1} timeout="auto" unmountOnExit>
         <Typography id={"TpostStory"+post._id}  variant="body1"   align="justify"    display="block"   color="textPrimary" />  
        </Collapse>
        </Grid>
        { post.story.length> 500 && 
         
            <Button  color="primary" className={classes.buttonPublish2} onClick={hanldeShowMore} fullWidth
             >
              {showMore === 0 ? 'more...' : (showMore === 1 ? 'less': '')}
           </Button>

        } 
          
     
        {!editStory&& 
       
        <Grid container spacing={2}  justify="center" alignItems="center">
              <Grid item xs>
                <TextField
                id="story-textarea"
                multiline
                InputProps={{ readOnly: editStory,}} 
                fullWidth
                defaultValue =  {post.story}
                rows={7}
                variant="outlined"
                size="medium"
                onChange={handlePostMessage}  />
              </Grid>
              <Grid container  spacing={2} >
                  <Grid item xs>
                  <Button
                      variant="outlined"
                      color="primary"
                      className={classes.buttonPublish}
                      onClick={publishPostRecord}
                      fullWidth
                    >
                        {isSending && <CircularProgress/>  }
                        {!isSending && <>  Save </>}
                  </Button>
                  </Grid>
                  <Grid item xs>
                  <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonPublish}
                        onClick={cancelPostRecord}
                        fullWidth
                      >
                          {isSending && <CircularProgress/>  }
                          {!isSending && <>  Cancel </>}
                    </Button>
                  </Grid>
              </Grid>
          </Grid>
        }
      </CardContent>

      
      <CardActions disableSpacing >
    
{/**
      <Badge badgeContent={1220} max={1000}  color="primary">
       <VisibilityIcon fontSize="small" />
      </Badge> */}

 
            <Grid className={classes.gridUi} container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                      <IconButton  aria-label="view" onClick={()=>handlePostDialogView(true)}>
                        <Badge  color="primary"  badgeContent={post.countView}  max={999} >
                          <VisibilityIcon  fontSize="small" color={!tagPost?"disabled":tagPost.view?"primary":"disabled"} />
                              </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton   aria-label="feelings" onClick={()=>handleActionClick('like')}>
                        <Badge  color="primary"  badgeContent={post.countLike}  max={999} >
                          <ThumbUpIcon fontSize="small" color={!tagPost?"disabled":tagPost.like?"primary":"disabled"}/>
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton  aria-label="feelings"  onClick={()=>handleActionClick('disLike')} >
                        <Badge  color="primary"  badgeContent={post.countDisLike}  max={999}>
                          <ThumbDownIcon fontSize="small"  color={!tagPost?"disabled":tagPost.disLike?"primary":"disabled"}/>
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                    <Tooltip title="Copy and paste, this Post url to share.">
                      <IconButton  aria-label="url link"  
                            onClick={()=>copyToClipboard(`${process.env.GATSBY_HOST}?fid=${post._id}&where=mainPostId&pr=${post.sharedType.substr(0, 2)}`)} >
                         
                          <LinkIcon fontSize="small"  color="primary"/>
                        
                      </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
 
                    </Grid>
                    <Grid item>

                    <Tooltip title={tagPost&&tagPost.tag?'You are following this post':'Follow this post.'}>
                      <IconButton  aria-label="add to favorites" onClick={()=>handleActionClick('tag')}>
                        {tagPost&&(tagPost.tag?
                          <Badge classes={ {badge:classes.customBadge}}  badgeContent={post.countTag}  >
                             <LocalOfferIcon fontSize="small" color="primary"/>
                          </Badge>
                        :
                          <Badge color="primary"  badgeContent={post.countTag}  >
                            <LocalOfferIcon fontSize="small" color="primary"/>
                          </Badge>)
                        }
                        {!tagPost&& <Badge color="primary" badgeContent={post.countTag}  >
                            <LocalOfferIcon fontSize="small" color="disabled" />
                          </Badge>
                        }
                      
                      </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={ 
                            (post.userId === globalThis.PROFILE_UID && post.sharedType === 'shared') ? 'Already shared to your friends' : 
                            (post.userId !== globalThis.PROFILE_UID && post.sharedType === 'shared') ? 'Share it with your friends' : 
                            ( post.sharedType === 'friend') ? 'Only your friends can see this post.' : 'No need to share. Public Post'} >
                        <IconButton aria-label="feelings"  onClick={handleOpenConfirmShareDialog}>
                            <Badge badgeContent={post.shared}  max={999}>
                            <ShareIcon fontSize="small" color={!tagPost?"disabled":tagPost.shared?"primary":"disabled"} />
                            </Badge>
                        </IconButton>
                      </Tooltip>
                    </Grid>
        
              </Grid>
                 
      </CardActions>
      <Divider/>
   
      <Button  fullWidth color="primary" 
            className={classes.button} 
            aria-label="show more"
            onClick={handleExpandClick}
            endIcon={ <Icon className={classes.iconUi}>
              <ExpandMoreIcon  color="primary" className={clsx(  {
                [classes.expandOpen]: expanded,
              })}/>
            </Icon>}
        > 
            {post.countComment>0?post.countComment:""} Comments  {post.countShare>0?post.countShare:""} Shares              
        </Button>
  
         

      <Collapse in={expanded} timeout="auto" unmountOnExit>
      
         <Grid container>   
             <CommentEntry  tagPost={tagPost} post={post} /> 
         </Grid>
          <Grid container>
              {expanded &&
              <Comments  postId={post._id}/>    
            }  
          </Grid>

        {post.countComment > 3   ?
            <Button  fullWidth color="primary" 
            className={classes.button} 
            aria-label="show more"
            onClick={handleExpandClick}
            endIcon={ <Icon className={classes.iconUi}>
              <ExpandMoreIcon  color="primary" className={clsx(  {
                [classes.expandOpen]: expanded,
              })}/>
            </Icon>}
        > 
            {post.countComment>0?post.countComment:""} Comments  {post.countShare>0?post.countShare:""} Shares              
        </Button>
        :
        <></>
        }


      </Collapse>
      <ConfirmShareDialog 
        open={confirmSharedDialog} 
        post={post}
        handleCloseConfirmShareDialog={handleCloseConfirmShareDialog}
        handleConfirmShareDialog={handleConfirmShareDialog} />

     
     
     {postDialogView && <PostFullView post={post} tagPost={tagPost} friend={friend} postDialogView={postDialogView} handlePostDialogView={handlePostDialogView} />}
               
    </Card>

  
  );
}


export default Post;


