import React, {useState} from "react";
import { Link } from "gatsby";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SwipeableViews from "react-swipeable-views";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, Theme, createStyles, CardMedia, CardHeader, Container } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
 
import PostImage from '../templates/PostImage';
import Tooltip from '@material-ui/core/Tooltip';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 345,
      minHeight: 345,
    },
    cardMedia: {
      height: "200px"
    },
    storyUi:{
      maxHeight: 200,
    
    }
  }),
);
 

const CarouselPost =({posts}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(posts.length);
 
  const handleNext = () => {
    setActiveStep(activeStep+1);
  
  };

  const handleBack = () => {
    setActiveStep(activeStep-1);
  };

  const handleStepChange = (activeStep) => {
    setActiveStep(activeStep);
  };
 
  return (
 
      <Paper className={classes.root} elevation={0}>
        <div>
          <Typography>{posts[activeStep].title}</Typography>

          <AutoPlaySwipeableViews
            axis={"x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
           
            {posts.map((post, index) => {
             
             return(
                <div key={index}>
                  {
                    Math.abs(activeStep - index) <= 2 ?  
                    <Card>
                      {/**
                      <CardMedia
                        className={classes.cardMedia}
                        image={withPrefix(publicURL)}
                      />
                       */}
                    
                       <PostImage tileData={post.urlFiles} mainPostId={post.mainPostId} linkFiles={post.linkFiles} psHeight={0} source={'carousel'} isRssFeed={post.userName==='MANTAWI news'}/>
                    
                      <CardContent>
                   
                        <Typography gutterBottom variant="h5" component="h2" display="block" noWrap>
                          <Link to={`/?fid=${post._id}&where=mainPostId`}>{post.title}</Link>
                        </Typography>
                        <Typography  className={classes.storyUi} align = 'justify'   display="block" noWrap >{post.story}</Typography>

                   
                      </CardContent>
                    </Card>
                     :"" 
                    }
                </div>
               )
              })
            }
         </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                Back
              </Button>
            }
          />
        </div>
      </Paper>
     
    );
   
}

export default  CarouselPost;
