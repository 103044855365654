import React, {useEffect,useState,useRef} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StoreIcon from '@material-ui/icons/Store';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SettingsIcon from'@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
 
import {Link, Button } from 'gatsby-theme-material-ui';
import AppsIcon from '@material-ui/icons/Apps';
 
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Fab from '@material-ui/core/Fab';
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
 
import LoginModal from '../components/LoginModal';
import LeftDrawer from '../components/LeftDrawer';
import Posting from '../components/Posting';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import {UserProfile} from '../utils/mantawiObject';
import { MenuItem, Menu, Avatar, Container, CardMedia, CircularProgress } from '@material-ui/core';
import { DotsVertical } from 'mdi-material-ui';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorBoundary from '../utils/ErrorBoundary';
import Adds from '../components/Adds';
import Account from "../components/account";
import Posts from "../components/posts";
import Welcome from "../components/welcome";
import Helps from "../components/helps";
import PublicPost from "../components/public_posts";
import queryString from 'query-string';
import ContactUsForm from "../components/ContactUS";
import { red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import RegisteredDialog from '../templates/RegisteredDialog'
//import Cookies from 'universal-cookie';
const drawerWidth = 300;
const drawerWidthRight = 240;
globalThis.LOADING_IMAGE =  require('../images/loading.gif');
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
 
  toolbar: { 
      paddingBottom: '10px',
      color: 'white'
  },
  toolbar2:{
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarClipped: {
    zIndex: theme.zIndex.drawer + 1,
    marginTop: '15px' ,
    height: '50px', 
    background: orange[500]
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerRight: {
    width: drawerWidthRight,
    flexShrink: 0,
  },
  drawerPaperRight: {
    width: drawerWidthRight,
  },
  headerSpacer: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 50,
    ...theme.mixins.toolbar,
     
  },
  headerSpacer2: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 60,
  },
  headerSpacer3: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 75,
    paddingRight: 5,
    paddingLeft: 5,
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
   
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    maxWidth: "auto",
    zIndex: 0,
    overflow: 'auto',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1,
    marginLeft: 0,
    maxWidth: "auto",
    zIndex: 0,
    overflow: 'auto',
  },
  content2: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
   
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    maxWidth: "100%",
    zIndex: 0,
    overflow: 'auto',
  },
  contentShift2: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1,
    marginLeft: 0,
    zIndex: 0,
    overflow: 'auto',
  },
  typography: {
    useNextVariants: true,
  },  
  topWhiteBar:{
    height: '15px',
    width: '100%',
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex:  theme.zIndex.drawer + 1 
  },
  fab: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
    backgroundColor: green[300],
    zIndex: theme.zIndex.drawer + 1 
  },
  fabMap: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: '20px',
    left: '55px',
    position: 'fixed',
    backgroundColor: 'transparent',
    background: 'transparent',
   
    zIndex: theme.zIndex.drawer + 1 
  },
  fabMap2: {
    margin: theme.spacing(1),
    top: '110px',
    right: 'auto',
    bottom: 'auto',
    left: '90px',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1 
  },
  fabMap3: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: '20px',
    left: '55px',
    position: 'fixed',
   
    zIndex: theme.zIndex.drawer + 1 
  },
  footer: {
    display: 'flex',
    height: '50px',
    width: '100%',
    left: 0,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    background: orange[500]
  },
 
  footerDialog: {
    display: 'flex',
    height: '50px',
    width: '100%',
    left: 0,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1 
  },
  loginButton: {
    color: 'white',
    textDecoration: 'none',
  },
  menuButton2: {
    color: 'white',
    textTransform: 'none',
  },
  dotsVerticalIcon: {
    color: "#efefef"
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: red[500],
  },
  adds:{
    width: '160',
    height: '600',
    columnCount: 1
  },
  linkButton:{
    color: 'blue',
  },
  avatar: {
    backgroundColor: red[500],
  }
  
}));

let openDrawer  = true;
let openMapDialog = false;

 
var d = new Date();
var m = d.getMonth() + 1;
var today = d.getFullYear()+'-'+ 
    (m<10? ('0'+m):m)+'-'+
    (d.getDate()<10? ('0'+d.getDate()):d.getDate())
 
 
globalThis.POSTS_QUERY =["postType","==","post"]
//const cookies = new Cookies();
//document.cookie = 'paypal=http://paypal.com/; SameSite=None; Secure';
globalThis.SHOWPAGE = 3;

function linkify_o(text) {
  var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
  });
}

function createInnerHtml(key, story){
  var ht = linkify_o(story);
  document.getElementById(key).innerHTML =ht;
}
  

const Layout = (props) => {
    const {isLoggedIn, profile} = useAuth();
  
 //  cookies.set('SameSite', 'None', { path: '/', sameSite:'none' , secure: true});
    //const [queryFilter,setQueryFilter] = useState( {data:["postType","==","post"]});
    const theme = useTheme();
    const classes = useStyles();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
    const [isFullScreen, setIsFullScreen] = useState(false);
     
   // const params = queryString.parse( props.location.search);
    const avatarRef = useRef(null);
    const [myFriends, setMyFriends] = useState([]);
   
   // const [showPage, setShowPage] = useState(3);

    const [showPage, setShowPage2] = useState(3);
    const setShowPage = (page) =>{
      globalThis.SHOWPAGE = page;
      setShowPage2(page);
    }
    const [urlLocation, setUrlLocation] = useState(props.location);
 
    const [open, setOpen] = React.useState(openDrawer);
    
    const [openPost, setOpenPost] = React.useState(false);
    
  
    const [toggleLogin, setToggleLogin] = React.useState(false);
    
    const [userProfilePicture, setUserProfilePicture] = React.useState(globalThis.USER_PROFILE_PICTURE);
 
  

  const [userProfile, setUserProfile] = useState<UserProfile>(null);

  const handleUserProfilePictureURL = (u)=>{

    setUserProfilePicture(u);
    globalThis.USER_PROFILE_PICTURE = u;
  
    // userProfilePictureUrl = u;
    
    setAnchorElAvatar(null);
  }
    const [userId, setUserId] = useState(null);
    
    useEffect(()=>{
       
      if(profile){
      
            globalThis.PROFILE_UID = profile.uid;
       
        globalThis.PROFILE_USERNAME = profile.displayName ;
        setRegisteredOpen(false);
        firestore.collection("users").doc(profile.uid).get().then(
          function(doc){
            console.log("it should happened.....");
            if (doc.exists) {
              var data = doc.data();
              console.log('name: ' + data.name );
              setUserProfile(data as UserProfile);
              globalThis.PROFILE_USERNAME = data.name;
              handleUserProfilePictureURL(data.profilePicture);
              setUserId(profile.uid);
            
            } else {

              console.error("layout this should not happened.....")
             
   
            }
          }

        ).then(()=>{
         // avatarRef.current &&  avatarRef.current.focusVisible();
         setIsLoadingFriends(true);
        var wait = useFirestoreQueryFriends(firestore.collection("friends").where("userId", "==", globalThis.PROFILE_UID))
        
       //  navigate("/?welcome="+Date.now());
        // setShowPage(2);
        }).then(()=>{
          setShowPage(2);
       
        //  navigate("/?welcome="+Date.now());
        //  window.location.assign(`/?kil=${globalThis.PROFILE_UID}&fid=post&where=postType`);
         
        }).catch((error)=>{
          console.error(error);
        }); 
   
      }else{
         
      }
     
      if(process.env.GATSBY_LIMIT_STATUS!=="-development"){
         //console.clear();
      }
    } ,[profile]);

    
    const handleDrawer = () =>{
      openDrawer = !openDrawer;
      setOpen(openDrawer)
    }

    const closedLeftDrawer = ()=>{
      var closingLeftDrawer = false;
      if(!matchesMD&&!matchesSM&&matchesXS) {
         
        closingLeftDrawer = true;
      }else{
    
        closingLeftDrawer = false;
      }

      if(closingLeftDrawer){
        handleDrawer();
      }
 
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElAvatar, setAnchorElAvatar] = React.useState<null | HTMLElement>(null);


    const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClickAvatar = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElAvatar(event.currentTarget);
    };
 
  
    const handleCloseMobile = () => {
      setAnchorEl(null);
    };
    const handleCloseAvatar = () => {
      setAnchorElAvatar(null);
    };
  

    const handleOpenPostDialog = (dialogBox: string ) =>{
      if(profile === null || profile === undefined){
       
        navigate("/?login=now");
      }else{ 
        
        setOpenPost(true)
      
      }
    }

    const handleClosePostDialog = (dialogBox: string) =>{
      console.log("handleClosePostDialog: " + dialogBox);
      setOpenPost(false)
    }
    
 

    const handleSignOut = async () => {
      console.log('sign out');
     // auth.signOut();
      await firebase.auth().signOut();
      globalThis.USER_PROFILE_PICTURE =null;
      
      globalThis.PROFILE_UID =  null; 
      setShowPage(0);
      handleUserProfilePictureURL(null);
      navigate("/");
    }

    const handleShowHome = (url) =>{
      if(isLoggedIn){
        setShowPage(2);
        navigate(url);
      }else{
        setShowPage(0);
        navigate(`?fid=post&where=postType`);
      }
      
    }


  // const [friends, isLoadingFriends] = useFirestoreQuery(
  //     firestore.collection("friends").where("userId", "==", profile.uid)
  //   );
  
  const [friends, setFriends] = useState<firebase.firestore.DocumentData[]>([]);
  const [isLoadingFriends, setIsLoadingFriends] = useState(false);
  const [friendHasLoaded, setFriendHasLoaded] = useState(false);
  const [registeredOpen, setRegisteredOpen] = useState(false);

  const handleRegisteredDialog = (status) =>{
    setRegisteredOpen(false);
    if(status ==='register'){
      navigate("/?login=now");
    
    }else{
      navigate("/?fid=public");
    }
   
  }
 
  const useFirestoreQueryFriends = async <T extends firebase.firestore.DocumentData>(
      query: firebase.firestore.Query,
    )  => {
      
      const unsubscribe = query.onSnapshot(
        (querySnapshot: firebase.firestore.QuerySnapshot) => {

          setFriends(
            querySnapshot.docs.map(doc => ({
              _id: doc.id,
              ...(doc.data() as T),
            })));
          setFriendHasLoaded(true);
          setIsLoadingFriends(false);
        },
        (err: Error) => {
          console.log("useFirestoreQueryFriends: " + err);
          console.error(err);
        });
      
      return   () => unsubscribe();
    }

  useEffect(() => {
      
       console.log("showPage location change: " +  globalThis.SHOWPAGE);
      const params = queryString.parse( props.location.search);
      if(params.login && !isLoggedIn){
           setToggleLogin(true);
      } else if(params.pr && params.pr !== "pu" && !isLoggedIn ){ 
         setShowPage(3);
         setRegisteredOpen(true);
      } 
      else{
        
          //future updates check the post if userid exists in loginuser is friend with post
          //user

          if(isLoggedIn && friendHasLoaded){
            setShowPage(2)
          } else{
            setShowPage(0)
          }
          console.log("index wheretext and whervalue change: " +  globalThis.SHOWPAGE);
          setUrlLocation(props.location)
      }
      if(process.env.GATSBY_LIMIT_STATUS!=="-development"){
       // console.clear();
     }
  },[props.location]);

  useEffect(() => {

    const params = queryString.parse( props.location.search);
    var _friends =[];
    friends.forEach( (f) => {
      _friends.push(f.friendId);
    });
    _friends.push(globalThis.PROFILE_UID?globalThis.PROFILE_UID: params.kil?params.kil:"");
    globalThis.MY_FRIENDS = _friends;
    
    console.log("index globalThis.MY_FRIENDS: "+ globalThis.MY_FRIENDS);
     
},[friends]);

  useEffect(() => {
    
     if(isLoggedIn){
        globalThis.PROFILE_UID = profile.uid;
        createInnerHtml("indexAdd",
         `<script type="text/javascript">
            atOptions = {
              'key' : '13c7c6e28663422dc77409a6699851de',
              'format' : 'iframe',
              'height' : 300,
              'width' : 160,
              'params' : {}
            };
            document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.topdisplaynetwork.com/13c7c6e28663422dc77409a6699851de/invoke.js"></scr' + 'ipt>');
         </script>`
        )
     }else{
      createInnerHtml("indexAdd",
        `<script type="text/javascript">
          atOptions = {
            'key' : '76e4d974f930d04b0318ff95c009f663',
            'format' : 'iframe',
            'height' : 600,
            'width' : 160,
            'params' : {}
          };
          document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.topdisplaynetwork.com/76e4d974f930d04b0318ff95c009f663/invoke.js"></scr' + 'ipt>');
      </script>`
      )
     }
    
  },[isLoggedIn]);

  const closeCommonDialog = () =>{
    console.log("closeContactUs");
    setShowPage(2);
  }

  const handleWelcomePage = () =>{
    setShowPage(3);
   // navigate("/?mid=welcome");
  }

  const handleAccountPage = () =>{
    setShowPage(1);
  //  navigate("/?mid=account");
  }

  const handleHelpPage = () =>{
    setShowPage(4)
  //  navigate("/?mid=help");
  }

  const handleContactPage = () =>{
    setShowPage(5)
   // navigate("/?mid=contactus");
  }

 

  const handleLogin=(status)=>{
    if(status){
       navigate("/?login=now");
    }
  }

 
  useEffect( ()=>{
    console.log("matchesMD matchesSM matchesXS 2: " + matchesMD + " : "  +matchesSM + " : " + matchesXS)
    if(!matchesMD&&!matchesSM&&matchesXS) {
      setIsFullScreen(true);
    } else{
      setIsFullScreen(false);
    }
  },[matchesMD,matchesSM,matchesXS])

  

    const data = useStaticQuery(graphql`
          query{
              site{
                  siteMetadata{
                      title
                      contact {
                        email
                        phone
                      }
                  }
              }
          }
      `)
    
   
    return (
   
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.topWhiteBar}></div>
       
        <AppBar
          position="fixed"
          className={classes.appBarClipped} 
        >
          <Toolbar className={classes.toolbar}>
        
            <Button onClick={handleDrawer}  >
                    <MenuIcon  className={classes.menuButton2}  />
            </Button>

            <div>
              <Typography variant="h6" noWrap>
                
                {data.site.siteMetadata.title} {/**isLoggedIn && '-'+profile.email+':'+profile.displayName*/}
              </Typography>
            </div>

            <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={3}
            >
              {/**
            <Grid item>     
                    <Fab size="small" aria-label="map" className={classes.fabMap} onClick={() => handleMapDialog("layoutOpen","mainsearchmap")}>
                      <SearchIcon />
                    </Fab>
             </Grid>   
             */}
 
              <Grid item>
                  <Hidden smDown>
                      <div className={classes.toolbar2}>


                        {isLoggedIn && 

                        <Container>
         
                           <Button className={classes.menuButton2} onClick={()=>{handleShowHome(`?kil=${profile?profile.uid:""}&fid=post&where=postType`)}}>Home</Button>
                           
                          <Button className={classes.menuButton2} onClick={handleHelpPage}>Help</Button>
                          
                          <Button className={classes.menuButton2} onClick={handleContactPage}>Contact Us</Button>
                                
                          <IconButton size="medium" action={avatarRef} onClick={ (e) => {handleClickAvatar(e)} } >
                                <Avatar  className={classes.large}
                                  alt={`Avatar n°${globalThis.PROFILE_UID}`}
                                  src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${globalThis.PROFILE_UID}?alt=media`}
                                  >
                                    { globalThis.PROFILE_USERNAME}
                                </Avatar>
                           </IconButton>
 
                         <Menu
                            id="simple-menu-avatar"
                            anchorEl={anchorElAvatar}
                            keepMounted
                            open={Boolean(anchorElAvatar)}
                            onClose={handleCloseAvatar}
      
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                         
                          >
                          
                            {isLoggedIn && 
                              <div>
                               
                                   <MenuItem onClick={()=>{setShowPage(1)}}> Profile</MenuItem>
                                   <MenuItem onClick={()=>{setShowPage(5)}}> Contact Us</MenuItem>
                                   <MenuItem onClick={handleSignOut} > Sign Out</MenuItem> 
                                </div>
                            }
                           
                          
                          </Menu>
                           
                          </Container>
                        }
                      
                          
                        {!isLoggedIn && 
                        <Grid container  direction="row" justify="center" alignItems="center">
                          <Grid item>
                              <Button className={classes.menuButton2} onClick={()=>{handleShowHome(`?v1`)}}>Home</Button>
                          </Grid>
                          <Grid item>
                              <Button className={classes.menuButton2}  onClick={handleHelpPage}>Help</Button>
                          </Grid>
                          <Grid item>
                              <Button className={classes.menuButton2}  onClick={handleContactPage}>Contact Us</Button>
                          </Grid>
                          <Grid item>
                            <Button className={classes.loginButton} onClick={() => {   handleLogin(true); }}  >
                              Sign In
                            </Button>
                          </Grid> 
                          </Grid>
                        }
                     </div>    
                  </Hidden>
                  <Hidden mdUp>
                  <IconButton onClick={handleClickMobile}>
                    <DotsVertical className={classes.dotsVerticalIcon} />
                  </IconButton>
                    
                    <Menu
                      id="simple-menu-mobile"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMobile}
                    >
                    
                      {isLoggedIn && 
                        <Container>
                         
                          <MenuItem onClick={()=>{handleShowHome(`?kil=${profile?profile.uid:""}&fid=post&where=postType`)}}>Home</MenuItem>
                          <MenuItem onClick={handleHelpPage}>Help</MenuItem>
                          <MenuItem onClick={handleContactPage}>Contact Us</MenuItem>
                          <MenuItem onClick={handleSignOut} > Sign Out</MenuItem> 
                          </Container>
                      }
                        
                      {!isLoggedIn && 
                        <MenuItem onClick={() => {handleLogin(true);}}  >Sign In</MenuItem>  
                      }
                    </Menu>
                    
                  </Hidden>
                </Grid>
                 
                </Grid>
          </Toolbar>
        </AppBar>
          
        <Tooltip title="Create a new Post">
        <Fab size="large" aria-label="add" className={classes.fab} onClick={() => handleOpenPostDialog("layoutOpen")}>
            <AddIcon />
        </Fab> 
        </Tooltip>
     {/**     <Fab size="large" aria-label="map" variant="extended"   className={classes.fabMap} >
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" value="_donations" />
    <input type="hidden" name="business" value="2MEJAX4YLGCAU" />
    <input type="hidden" name="item_name" value="Help us, maintain this site" />
    <input type="hidden" name="currency_code" value="PHP" />
    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    </form> 
          </Fab>*/}

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
           
          <Container  className={classes.headerSpacer2}>
            <List>
       
                <ListItem button color="inherit" key='Welcome'  onClick={handleWelcomePage}>   
                  <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
                    <ListItemText className={classes.linkButton} primary='Welcome' />
                     
                </ListItem>
               
              </List>  
            </Container>
         
          <Divider />
          
        {profile && <LeftDrawer profileId={profile.uid} closedLeftDrawer ={closedLeftDrawer} /> }
         
          <Divider />
         
          <List>
          
            {profile &&  
             <ListItem button key='Account' onClick={handleAccountPage}>
                  <ListItemIcon>
                  <Avatar className={classes.avatar} alt={`Avatar n°${globalThis.PROFILE_UID}`}
                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${globalThis.PROFILE_UID}?alt=media`}>
                      { globalThis.PROFILE_USERNAME}
                  </Avatar>
                    </ListItemIcon>
                    <ListItemText  className={classes.linkButton} primary='Account Settings' /> 
            
              </ListItem>}
     
              <ListItem>
          
              <Grid  className={classes.fabMap2} container  >
{/**
                 <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="BFQJ36PHWULPS" />
                  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                  <img alt="" border="0" src="https://www.paypal.com/en_PH/i/scr/pixel.gif" width="1" height="1" />
                </form>
                 */}
              </Grid>
            </ListItem>
  
           
          </List>

              {
               (profile===null || profile === undefined) ?
                  <Container>
                    <Grid container  direction="column" justify="center"  alignItems="center">
                          <Grid item>
                            {process.env.GATSBY_ADDS_ENABLE === 'true' &&

                                  <CardMedia  style={{ width:160, height: 620, border: 0, borderColor: "white" }} scrolling="no" component='iframe' title="Ads" 
                                  src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds160x600.html?alt=media&token=091aaa65-5262-40ea-b685-6909263290a1`}></CardMedia>
                            }
                            <div id="indexAdd" style={{ width:160, height: 620, border: 0, borderColor: "white" }} >

                            </div>
                          </Grid>
                          
                      </Grid>
                  </Container>
                  :
                  <Container>
                  <Grid container  direction="column" justify="center"  alignItems="center">
                        <Grid item>
                        {process.env.GATSBY_ADDS_ENABLE === 'true' &&
                                <CardMedia  style={{ width:160, height: 320, border: 0, borderColor: "white" }} scrolling="no" component='iframe' title="Ads" 
                                src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds300x160.html?alt=media&token=381f3ea4-f7ce-48da-ae73-7daf641d92e2`}></CardMedia>
                        } 
                        <div id="indexAdd" style={{ width:160, height: 320, border: 0, borderColor: "white" }} >

                        </div>
                      </Grid>
                    </Grid>
                  </Container>
              }
          </div>
        </Drawer>
   
        <Hidden smDown>
           
            <main  className={clsx(classes.content, { [classes.contentShift]: open, })}>
              <div className={classes.headerSpacer} />
            {/**
                  {props.children} */}
                    { (!isLoggedIn &&  showPage ===0) ?<PublicPost location={urlLocation}  /> :
                      (isLoggedIn &&  showPage === 1) ? <Account setShowPage={setShowPage}/> :
                      (isLoggedIn &&  showPage === 2 && globalThis.MY_FRIENDS!==null) ? <Posts location={urlLocation} friends={friends} isLoggedIn={isLoggedIn} /> :
                      ( showPage === 3 ) ? <Welcome /> :
                      ( showPage === 4 ) ? <Helps /> :
                      ( showPage === 5 ) ? <ContactUsForm setShowPage={setShowPage}/> :
                        <Typography variant="body2" color="textSecondary" align="center">
                           <img  src={globalThis.LOADING_IMAGE} alt={'loading'}/>
                        </Typography>   
                    } 

                    {/**
SHOWPAGE_PUBLICPOST ==0
SHOWPAGE_ACCOUNT=1
SHOWPAGE_POST=2
SHOWPAGE_WELCOME=3
SHOWPAGE_HELPS=4
SHOWPAGE_CONTACTUS=5


 
                    {  showPage === 5  && <ContactUsForm setShowPage={setShowPage}/> }
                    {  showPage === 4  && <Helps /> }
                    {  showPage === 3  && <Welcome /> }
                    {(isLoggedIn && showPage === 2 && globalThis.MY_FRIENDS!==null) ? <Posts location={urlLocation} friends={friends} isLoggedIn={isLoggedIn}/> : <Typography variant="body2" color="textSecondary" align="center">
                      <img  src={globalThis.LOADING_IMAGE} alt={'loading'}/>
                      </Typography>   } 
                    {(isLoggedIn && showPage === 1 ) && <Account setShowPage={setShowPage}/> }
                    { (!isLoggedIn && showPage ===0) && <PublicPost location={urlLocation}/>}
                     */}
            </main>
  
         
        </Hidden>
        <Hidden mdUp>
           
            <main  className={clsx(classes.content2, { [classes.contentShift2]: open, })}>
              <div className={classes.headerSpacer} />
            {/**
                  {props.children} */}
                  {/**
              {  showPage === 5  && <ContactUsForm setShowPage={setShowPage}/> }
              {  showPage === 4  && <Helps /> }
                    { showPage === 3  && <Welcome /> }
                    {(isLoggedIn && showPage === 2 && globalThis.MY_FRIENDS!==null) ? <Posts location={urlLocation} friends={friends} isLoggedIn={isLoggedIn}/> : <Typography variant="body2" color="textSecondary" align="center">
      <img  src={globalThis.LOADING_IMAGE} alt={'loading'}/>
    </Typography>   } 
                    {(isLoggedIn && showPage === 1 ) && <Account setShowPage={setShowPage}/> }
                    { (!isLoggedIn && showPage ===0) && <PublicPost location={urlLocation}/>}
                    */}    
                    
                    { (!isLoggedIn &&  showPage ===0) ?<PublicPost location={urlLocation}  /> :
                      (isLoggedIn &&  showPage === 1) ? <Account setShowPage={setShowPage}/> :
                      (isLoggedIn &&  showPage === 2 && globalThis.MY_FRIENDS!==null) ? <Posts location={urlLocation} friends={friends} isLoggedIn={isLoggedIn}  /> :
                      ( showPage === 3 ) ? <Welcome /> :
                      ( showPage === 4 ) ? <Helps /> :
                      ( showPage === 5 ) ? <ContactUsForm setShowPage={setShowPage}/> :
                        <Typography variant="body2" color="textSecondary" align="center">
                           <img  src={globalThis.LOADING_IMAGE} alt={'loading'}/>
                        </Typography>   
                    } 
            </main> 
        </Hidden>
 
        {openPost && <Posting openPostDialog={openPost} handleCloseDialogPost={handleClosePostDialog} userProfile={userProfile} matchesXS={isFullScreen}/>}
        {toggleLogin && <LoginModal setToggleLogin={setToggleLogin}  />}

        {registeredOpen && <RegisteredDialog 
          open={registeredOpen} 
          setRegisteredOpen={setRegisteredOpen}
        handleRegisteredDialog={handleRegisteredDialog} /> }

     
        <Hidden smDown>
          <Drawer
              className={classes.drawerRight}
              variant="persistent"
              anchor="right"
              open={true}
              classes={{
                paper: classes.drawerPaperRight,
              }}
          > 
           <div className={classes.headerSpacer3}>
            
                 <Adds/>      
           </div>
          </Drawer>
        </Hidden>
      </div>
   
    );
}
 
 
 export { Layout as default}
 