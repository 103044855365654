import React ,{useEffect ,useState} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withPrefix , Link} from "gatsby";
import { CardMedia, Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import orange from "@material-ui/core/colors/orange";
//import captureWebsite  from 'capture-website';
//import { capture, OutputType } from 'html-screen-capture-js';
//const useStyles = (psHeight) => makeStyles( (theme: Theme) =>
//https://stackoverflow.com/questions/15273042/catch-error-if-iframe-src-fails-to-load-error-refused-to-display-http-ww#:~:text=your%20web%20app%20would%20make,know%20the%20IFrame%20will%20error.
const useStyles = makeStyles( (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: "rgba(0,0,0,.03)", 
 
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      minHeight: 300
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    backgroundUrl:{
      
      width: '100%'
   
    },
    backgroundUrl2:{
      height: 200
    },
    adds1:{
      minWidth: 250,
      maxHeight: 300,
      minHeight: 300,
      
      maxWidth: 500,
      paddingTop: 5,
      display: 'flex',
    } ,
    adds2:{
      width: '95%',
      maxHeight: 300,
      minHeight: 300,
      minWidth: 300,
      paddingTop: 5,
    } ,
    adds3:{
      width: 470,
      height:   80
    } ,
    imageUi3:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 250,
      minWidth: 250,
    },
    imageUi2:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 'auto',
      minWidth: 250,
    },
    imageUi1:{
      maxHeight: 300,
      minHeight: 300,
      width: 'auto',
      maxWidth: '100%-20',
      minWidth: 'auto'
    },
    imageLinks:{
      maxHeight: 300,
      minHeight: 300,
      width: '90%',
      maxWidth: '100% -20px',
      minWidth: 250,
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    advertisementUi:{
      background: orange[500]
    }
   
  }),
);
  
const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fposts%2F`
const postImageUrl = (mainPostId: string, tile: any) => {
 // c6qJ7um8DLdNTX2WBRMM
  console.log("postImageUrl: " + tile);
  if(tile.source && tile.source=== 'rss'){
    return tile.imageUrl;
  }
  const imageName = tile.fileName;
  const lastDot = imageName.lastIndexOf('.');
  const fileName = imageName.substring(0, lastDot);
  const ext = imageName.substring(lastDot + 1);
   
  // currently it is converting to 400x400??
  //const newName = `thumbnails%2F${fileName}_400x400.${ext}`;
  const newName = `thumbnails%2F${fileName}_200x200.${ext}`;
  
  return `${imageUrl}${mainPostId}%2F${newName}?alt=media`;

}

const screeshotUrl = (mainPostId) => { 
 // console.log("screenshot url: " + mainPostId); 
  return `${imageUrl}${mainPostId}%2F${mainPostId}.png?alt=media`;
}


const addsUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/adds%2Ftest1%2F`
const addsImageUrl =  () => {
  const  fileName= Math.floor(Math.random() * 19) + 1;
 
  const newName = `thumbnails%2F${fileName}_200x200.jpg`;
 //const newName = `thumbnails%2F${fileName}_200x200.jpg`;
   return `${addsUrl}${newName}?alt=media`;

}
 
const sponsorImageUrl =  () => {
  const  fileName= Math.floor(Math.random() * 20) + 1;
  const newName = require(`../images/sponsor/${fileName}.jpg`);
  return newName;
}


const backgroundImageUrl =  () => {
  const  fileName= Math.floor(Math.random() * 40) + 1;
  const newName = require(`../images/background/${fileName}.jpg`);
  return newName;
}



const PostImage = ({tileData, mainPostId, linkFiles , psHeight, source, isRssFeed}) =>  {
  //const classes = useStyles(psHeight)();
  const classes = useStyles();
  var useDefaultImage = false;
   
  const [ imageUrl, setImageUrl] = useState(sponsorImageUrl());
  const [ backgroundUrl, setBackgroundUrl] = useState(backgroundImageUrl());
  const [ colsCount, setColsCount] = useState(1);
  const [ showVideo, setShowVideo] = useState(false);
  const [ showLink, setShowLink] = useState(false);
  const [ whatAdds, setWhatAdds ] = useState(1);
  // const [ error, setIsError] = useState(false);
  // const [ imageSource , setImageSource] = useState(linkFiles&&linkFiles[linkFiles.length-1]);

  if(tileData && tileData.length >0){
    useDefaultImage=true;
  }
 
  const onErrorImg = (event:  any) =>{
   
     event.target.src= require('../images/error_image.png');
  }
 
   
  // const handleImageError = (event)  =>{  
    
  //     const isLoaded = event.target.contentWindow.window.length ;
  //     console.log("xxxxxxxxxxxx : "  + isLoaded);
  //     if(isLoaded === 0){
  //       setIsError(true);
  //       // event.target.className=classes.imageUi1;
  //       // event.target.src= require('../images/error_image.png');
  //     }else{
  //       setIsError(false);
  //     }
  
  // }

 
  // useEffect(()=>{
  //   if(error){
  //     setImageSource(require('../images/error_image.png'));
  //   }else{
  //     setImageSource(linkFiles[linkFiles.length-1]);
     
  //   }
     
  // },[error])
   
  useEffect(()=>{

    if( tileData.length >= 3){
      setColsCount(2.5)
    }else if(tileData.length > 1){
      setColsCount(2)
    }else{
      setColsCount(1)
    }
   // console.log("Post useEffect");
  //  setImageUrl(  addsImageUrl() );
   
  },[tileData.length]);
 
  const whatAddsUrl = () =>{
    const w = 1 ; // Math.floor(Math.random() * 2) + 1;
     
    if(w === 1){
      return `https://www.profitablegate.com/fmdbtqnz?key=05a3f2e55697fc8c7d93bc7472350a74` ;
    }else{
      return `//graizoah.com/afu.php?zoneid=3408414`;
    }
  }

  useEffect(()=>{

    if(source==='carousel'){
      setWhatAdds(1);
      
    }else{ 
      setWhatAdds(Math.floor(Math.random() * 3) + 1);
    }
  })


  
  useEffect(()=>{
  
    if(linkFiles === undefined){

    }else if(linkFiles.length>0){ 
      if(linkFiles[linkFiles.length-1].indexOf('youtube.com')>0 || linkFiles[linkFiles.length-1].indexOf('vimeo.com')>0 ){
        setShowVideo(true);
        setShowLink(false);
      }else{
         setShowVideo(false);
         if(tileData && tileData.length > 0){
          setShowLink(false);
         }else{ 
          setShowLink(true);
         }
      }
   }
    
  },[linkFiles]);
  
  // useEffect(()=>{
  
  //   if(linkFiles === undefined){

  //   }else{ 
  //     if(linkFiles.length>0){
  //       setShowLink(true);
  //     }else{
  //       setShowLink(false);
  //     }
  //  }
    
  // },[linkFiles]);
  // const obj = capture();
   
  // const [websiteImage, setWebsiteImage] = useState(undefined);
  // useEffect(()=>{
  //  if (linkFiles[linkFiles.length-1].indexOf('youtube.com')===-1 ||
  //   linkFiles[linkFiles.length-1].indexOf('vimeo.com')===-1 ) {
  //      (async () => {
  //       const wi = await   capture(OutputType.BASE64, document, {'imageFormatForDataUrl': 'image/jpeg', 'imageQualityForDataUrl': 1.0});
  //        setWebsiteImage(wi);
  //       })();
  //   }

  // },[linkFiles.length])

  return (
 
      <div className={classes.root}>

          {/** screenshot is disabled
            showLink && ( 
               (linkFiles[linkFiles.length-1].indexOf('youtube.com')>0 || linkFiles[linkFiles.length-1].indexOf('vimeo.com')>0 )?
                 <ReactPlayer className={classes.imageUi1}  url={linkFiles[linkFiles.length-1]} light controls  />
                 :
                 
                 <a href={linkFiles[linkFiles.length-1]} target="_blank">
                   <CardMedia className={classes.adds} component='img' title={linkFiles[linkFiles.length-1]} image={screeshotUrl(mainPostId)}  onError={onErrorImg} />
                </a>  
            )
           */}
          
          

          {
            showVideo &&  <ReactPlayer className={classes.imageUi1}  url={linkFiles[linkFiles.length-1]} light controls  />    
          }

          {(showLink && process.env.GATSBY_ADDS_LINK_ENABLE ==="true") &&
             
            <CardMedia  id={`iframe-${mainPostId}`} className={classes.imageLinks}  sandbox="allow-same-origin allow-scripts allow-forms" style={{ border: 0, borderColor: "white" }} scrolling="no"  component='iframe' title="Ads" 
              src={linkFiles[linkFiles.length-1]}   ></CardMedia>
             
          }
          { (showLink && process.env.GATSBY_ADDS_LINK_ENABLE ==="false") &&
            <a href={`https://www.profitablegate.com/fmdbtqnz?key=05a3f2e55697fc8c7d93bc7472350a74`} target="_blank" >
             
               <img className={classes.adds1} src={ imageUrl} alt={'advertisement'}/>
              </a>
          }



        { (tileData.length > 2 && (!showLink && !showVideo)) &&
            <GridList cellHeight={300} className={classes.gridList} cols={colsCount}>
              {tileData.map((tile) => (
                <GridListTile key={tile.fileName} cols={1}>
                    <a href={`https://www.profitablegate.com/fmdbtqnz?key=05a3f2e55697fc8c7d93bc7472350a74`} target="_blank" >
             
                      <img  className={classes.imageUi3} src={postImageUrl(mainPostId,tile)} alt={ mainPostId + ">=3" + tile.fileName} 
                      onError={onErrorImg}
                  />
                </a>
              
                </GridListTile>
              ))}  
              
            </GridList>
        } 

        {(tileData.length === 2 && (!showLink && !showVideo)) &&
            <GridList cellHeight={300} className={classes.gridList} cols={colsCount}>
              {tileData.map((tile) => (
                <GridListTile key={tile.fileName} cols={1}>
                <img  className={classes.imageUi2} src={postImageUrl(mainPostId,tile)} alt={ mainPostId + ">=2" +tile.fileName} 
                    onError={onErrorImg}
                />
                </GridListTile>
              ))}    
            </GridList>
        } 
 
        {(tileData.length === 1  && (!showLink && !showVideo)) &&
          <GridList cellHeight={300} className={classes.gridList} cols={colsCount}>
            {tileData.map((tile) => (
              <GridListTile key={tile.fileName} cols={1}>
                
              <img  className={classes.imageUi1} src={postImageUrl(mainPostId,tile)} alt={ mainPostId + ">=1" + tile.fileName} 
                  onError={onErrorImg}
              /> 
              </GridListTile>
            ))}  
          
          </GridList> 
        } 

        {
             ( isRssFeed && tileData.length === 0 ) && 
           <Grid container>

             <Grid container spacing={1}  direction="column" justify="center"  alignItems="center">
               <Grid item xs>
                     <Typography className={classes.advertisementUi} variant="subtitle1" >Advertisement</Typography>
               </Grid>  
               <Grid item  className={classes.backgroundUrl2} >
                     <a href={whatAddsUrl()} target="_blank" >
                       <CardMedia  className={classes.backgroundUrl}  style={{ border: 0, borderColor: "white" , backgroundColor: "white"}}  component='img' title="Ads" 
                            image={ backgroundUrl }></CardMedia>
                     </a> 
               </Grid>  
             </Grid>
          </Grid>
        }
          
        { 
        
          (tileData.length === 0 && (linkFiles&& (linkFiles.length > 0 || linkFiles.length === 0  )&& (!showLink && !showVideo)) && process.env.GATSBY_ADDS_ENABLE === 'true') &&
             <Grid container>
              {whatAdds === 1 &&
                <Grid container spacing={2}  direction="column" justify="center"  alignItems="center">
                  <Grid item xs>
                        <Typography className={classes.advertisementUi} variant="caption" >Advertisement</Typography>
                  </Grid>  
                  <Grid item>
                        <a href={`https://www.profitablegate.com/fmdbtqnz?key=05a3f2e55697fc8c7d93bc7472350a74`} target="_blank" >
                          <img className={classes.adds1} src={ imageUrl} alt={'advertisement'}/>
                        </a> 
                  </Grid>  
                </Grid>
              }
              {whatAdds === 2 &&

              <Grid container spacing={2}  direction="column" justify="center"  alignItems="center">
              <Grid item xs>
                    <Typography className={classes.advertisementUi} variant="caption" >Advertisement</Typography>
              </Grid>  
              <Grid item container spacing={2}  direction="row" justify="center"  alignItems="center">     
                  <Grid item>
                          <CardMedia   className={classes.adds2}  style={{ border: 0, borderColor: "white" }} scrolling="no"  component='iframe' title="Ads" 
                          src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds300x250.html?alt=media&token=bfdf45f4-e9b9-465e-8855-5d4b81381018`}></CardMedia>
                  </Grid>     
                </Grid> 
              </Grid>
               
              }
              {whatAdds === 3 && 
              
                  <Grid container spacing={2} style={{   backgroundColor: "white"}}  direction="column" justify="center"  alignItems="center">
                    <Grid item xs>
                          <Typography className={classes.advertisementUi} variant="caption" >Advertisement</Typography>
                    </Grid>  
                    <Grid item  style={{   backgroundColor: "white"}}>  
                    
                      <CardMedia  className={classes.adds3}  style={{ border: 0, borderColor: "white" , backgroundColor: "white"}}  scrolling="no"    component='iframe' title="Ads" 
                            src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds468x60.html?alt=media&token=43437635-3a6d-4bb9-9563-9b9759848497`}></CardMedia>
                    </Grid>
                  </Grid>  
               
    
              }
             </Grid>
        }
 
    </div>
            
  );
}

export default PostImage;

// <GridListTileBar
//   classes={{
//     root: classes.titleBar,
//     title: classes.title,
//   }}
// title={tile.title}
// actionIcon={
//   <IconButton aria-label={`star ${tile.title}`}>
//     <StarBorderIcon className={classes.title} />
//   </IconButton>
// }
// />
 //https://github.com/CookPete/react-player

// https://openbase.io/js/capture-website   promising
//https://openbase.io/js/html-screen-capture-js
//working now
//https://medium.com/@dirk_hoekstra/building-a-website-screenshot-api-3aeb3f21b465

//zyFKuzsJvZwOoGBICb7M