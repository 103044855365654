import React , {useEffect,useState} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime, utcTimePlus, sortByProperty} from '../utils/MantawiTime'; 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "100%",
      overflow: 'auto',
    },
    listItemUi:{
      marginTop: '-10px',
      width: "100%"
    },
    commentUi:{
        width: "100%", 
    },
    loadingUi:{
        width: "100%",
        
    },
    small: {
      width: theme.spacing(1),
      height: theme.spacing(1),
    },
  }),
);
   
 

TimeAgo.addLocale(en);
var count = 0;
const Comments = ({postId}) => {

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
   
  const [comments, isLoading] = useFirestoreQuery(

       // firestore.collection("comments").where("postId", "==", postId)
       firestore.collection("comments").where("postId", "==", postId)
    
  );

  const [ sortComments, setSortComments]= useState([]);
 
  useEffect(()=>{
 
     if(comments && comments.length>0){
        comments.sort(sortByProperty("created"))
        setSortComments(comments);
       
     }
     
  },[comments]);

  return (
      <>
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
    <List className={classes.listUi} dense>
            {  sortComments.map((comment) =>(
            
              <ListItem className={classes.listItemUi} key={comment._id} alignItems="flex-start">
                
                 <Card className={classes.commentUi} variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar   aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${comment.userId}?alt=media`} >
                     {comment.name}
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings" >
                      <MoreVertIcon fontSize="small"/>
                    </IconButton>
                  }
                  title={comment.name}
                  
                  subheader={timeAgo.format(utcTimePlus(comment.created),'twitter')}
                />
              
                <CardContent className={classes.content}>
                  <Grid container >
                      <Grid item xs>
                        <Typography variant="body2" color="textPrimary" component="p">
                         {comment.message}
                        </Typography>
                    </Grid>
                  </Grid>

                </CardContent>
          
              </Card>
              </ListItem>
                
            ))}
          </List>
        }
   </>
  );
} 
export default Comments;


