import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {   makeStyles, Theme, createStyles } from '@material-ui/core/styles';
 


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    buttonUi: {
      textTransform: "none",
  
    },
  
  }),
);
export default function ConfirmShareDialog({open, post,  handleCloseConfirmShareDialog, handleConfirmShareDialog}) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  
  const classes = useStyles();

  return (
    
     
      <Dialog
        open={open}
        onClose={handleCloseConfirmShareDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Post"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
            {post.sharedType === 'shared' && `Share it with your friends, Continue?`}
            {post.sharedType === 'friend' && `You cannot share this post. The owner set it to Friends only.`}
            {post.sharedType === 'public' && `This post is set to Public, all user can view this post.`}
               
           </DialogContentText>
        </DialogContent>
        <DialogActions>
          { post.sharedType === 'shared'  && 
            <Button  variant="outlined" className={classes.buttonUi} onClick={handleConfirmShareDialog} color="primary">
              Share
            </Button>
          }
          <Button   variant="outlined" className={classes.buttonUi}  onClick={handleCloseConfirmShareDialog} color="primary" autoFocus>
        
           { post.sharedType === 'shared' ? 'Cancel' : 'Close'}
          </Button>
         
        </DialogActions>
      </Dialog>
  
  );
}