import React , {useState, useEffect} from 'react';
import {   makeStyles, Theme, createStyles } from '@material-ui/core/styles';
 
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
 
import Avatar from '@material-ui/core/Avatar';
 
import { red } from '@material-ui/core/colors';

import { Button, TextField, Icon, CardActions, Paper, Grid } from '@material-ui/core';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {utcTime} from '../utils/MantawiTime'; 

 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {   
      width: "100%",
    },
    avatar: {
      backgroundColor: red[500],
    },
    content:{
      marginTop: '-20px'
    },
    button: {
      textTransform: "none",
      marginTop: '5px',
      marginLeft: '6px',
      marginRight: '6px',
    },
    paper: {
      width: '100%'
       
    },
  }),
);
  
TimeAgo.addLocale(en);


const CommentEntry = ({tagPost,post}) => {
  const classes = useStyles();
 
  const [postMessage, setPostMessage] = useState("");
  const [isSending,setSending] = useState(false);

  useEffect( ()=>{
    setPostMessage("");
  },[isSending])

  const handlePostMessage = (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     

    setPostMessage(value);
      
  }

  const timeAgo = new TimeAgo('en-US');

  const handleCommentSend = () =>{
      setSending(true);
      var timeEntry = utcTime();
      if( postMessage.length > 0){
        var comment ={
           postId : post._id,
          userId : globalThis.PROFILE_UID ,
           name : globalThis.PROFILE_USERNAME,
           created : timeEntry,
           updated : timeEntry,
          message : postMessage,
        }
 
        console.log(  comment.created );
        firestore.collection("comments").add(comment).then(  
          (cRecord) =>{
            console.log('comment profile save' + cRecord.id);  
          }
       ).then(()=>{
        setSending(false);
        setPostMessage("");
        checkTagPost();
       }).catch( (error)=>{
        console.error(error);
      });
      }else{
        setSending(false);
      }
  }

  const checkTagPost = () =>{
   
    var key =post._id+globalThis.PROFILE_UID;

    if(tagPost){
       
       console.log("commentEntry: tagPost is null" );

    }else{
      //does not exists creat a new one
      var createdTime = utcTime();
      
      var _tagPost = {
        postId: post._id,
        authorId: post.userId,
        authorName:  post.userName,
        userId: globalThis.PROFILE_UID,
        story: post.title,
        view: true,
        tag: false,
        like: false,
        disLike: false,
        shared: false,
        hasUpdates: false,
        created: createdTime,
        updated: createdTime,
      }

      firestore.collection('tags').doc(key).set(_tagPost)
          .then( ()=>{
              console.log("tags send")  ;
          }
      ).catch( (error)=>{
        console.error(error);
      });
    }
    
    console.log("tagPost: " + tagPost);
  }
 
  //subheader={timeAgo.format(comment.created)} when save the created is set to - utc time offset 
  return (
    <Card className={classes.root} variant="outlined" raised>

<CardContent >
    
        <Grid  direction="row"
  justify="flex-start"
  alignItems="center"
  container wrap="nowrap" spacing={2}>
          <Grid item>
          <Avatar aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${globalThis.PROFILE_UID}?alt=media`}  className={classes.avatar}>
            {globalThis.PROFILE_USERNAME}
          </Avatar>
          </Grid>
          <Grid item xs zeroMinWidth>
              <TextField
                      id="postMessage-textarea"
                      label= ""
                      placeholder="? before you post..."
                      multiline
                      variant="outlined"
                      fullWidth
                      defaultValue = {postMessage}
                      color="primary"
                      rowsMax={4}
                      size="medium"
                      onChange={handlePostMessage}
                    />
          </Grid>
        </Grid>
     
       </CardContent>
       <CardActions className={classes.content}>
            <Button variant="outlined" className={classes.button} fullWidth color="primary" 
                onClick={handleCommentSend}  >
               {isSending ? <CircularProgress/> : 'Send'  }
             </Button>
      </CardActions>
      </Card>
  );
} 
export default CommentEntry;


 
   