import React , {useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, List, ListItem, ListItemAvatar, ListItemText, Divider, Grid, Container, TextField, Menu, MenuItem, CircularProgress, Icon, ListItemIcon, Link } from '@material-ui/core';
import Comments from './Comments';
import CommentEntry from './CommentEntry';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {utcTime,utcTimeOrg} from '../utils/MantawiTime'; 
import { OpenSourceInitiative, FireHydrantAlert } from 'mdi-material-ui';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import green from "@material-ui/core/colors/green";
import PostDialog from './PostDialog';
import SEO from '../components/SEO';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import ConfirmShareDialog from '../templates/ConfirmShareDialog';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
//const useStyles = ( props ) =>makeStyles((theme: Theme) =>
const useStyles =  makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 345,
      minHeight:  320,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    
    button: {
      textTransform: "none"
    },
    iconUi:{
        width:'100%',
        marginTop: -6
    },
    buttonPublish:{
      textTransform: "none",
      marginTop: '10px'
    },
    cardContent:{
      oveflow: "auto",
    },
    actionsUi1:{
      
      marginTop: '-15px',
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
      width: "50%"
    },
    actionsUi2:{
   
      marginTop: '-15px',
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
      float: "right",
      width: "50%"
    },
    cardActionsUi:{
      width: "100%"
    },
   gridUi:{
    
    marginTop: '-15px'
   },
   customBadge: {
    backgroundColor: "green",
    color: "white"
  },
  menuItemUi:{
    width: 'auto'
  },
  titleUi:{
    padding: 10,
 
  } ,
  menuItemSelected:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  adds:{
    width: 330,
    height: 80
  },
  sourceUi:{
    paddingLeft: 10,
  }
  
 
  }),
);
 

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      
      right: -20,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    }, 
  }),
)(Badge);

 
TimeAgo.addLocale(en);

function linkify_o(text) {
  var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
  });
}

function copyToClipboard(theLink) {
   
  const el = document.createElement('textarea');
  el.value = theLink;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
} 

function createInnerHtml(key, story){
  var ht = linkify_o(story);
  document.getElementById(key).innerHTML =ht;
 
}
 

const PostPublic = ({post, colCount }) => {

   //const classes = useStyles( {rootHeight: colCount ===12? 0: 300} )(); 
   const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [ postDialogView, setPostDialogView] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const handleExpandClick = () => {
   
    setExpanded(!expanded);
  };

  const handlePostDialogView =(status: boolean) =>{
    setPostDialogView(status);
  }

  const hanldeShowMore = () =>{
    
    setShowMore(!showMore);
  
 }

  const timeAgo = new TimeAgo('en-US');
  const [newComment, setNewComment] = useState(null);
  const [editStory, setEditStory] = useState(true);
  const [isSavingFriend, setIsSavingFriend] = useState(false);
  const postAndUserId = post._id+globalThis.PROFILE_UID;
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSending,setIsSending] = useState(false);
  const [postMessage, setPostMessage] = useState("");
 
  const preventDefault = (event) => event.preventDefault();

  // const createInnerHtml = () =>{
  //    document.getElementById("postStory").innerHTML = linkify_o(post.story);
    
  // }
  function textSlice(text, count){
   
    return text.slice(0, count) ;
  }
  
  useEffect(()=>{
    if(showMore){
      createInnerHtml("TpostStory"+post._id , post.story );
   
    }else{
      createInnerHtml("FpostStory"+post._id , textSlice(post.story, 500 ) );
    }
 
  },[ showMore]) 

  
  useEffect(()=>{
    if(showMore){
      createInnerHtml("TpostStory"+post._id , post.story );
   
    }else{
      createInnerHtml("FpostStory"+post._id , textSlice(post.story, 500 ) );
    }
 
  },[ colCount]) 

  const [psHeight, setPsHeight] = useState(300);
  // useEffect(()=>{
  //   if(colCount === 12){
  //     setPsHeight(0);
  //   }else{
  //     setPsHeight(300);
  //   }
  // },[ colCount]) 

  const isRSSFeed = (_name)=>{
    let _isRss = false;

    if(_name==="MANTAWI news"){
      _isRss = true;
    }else if(_name === "ASIAN reports"){
      _isRss = true;
    }

    return _isRss;
  }

  return (
    <Card className={classes.root} raised>
          <SEO title={post.title}>
           
          <meta
            name="description"
            content={`${post.title}`}
          />
           <meta
            name="url"
            content={`${process.env.GATSBY_HOST}?fid=${post._id}&where=mainPostId&pr=${post.sharedType.substr(0, 2)}`}
          />
        </SEO>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${post.userId}?alt=media`}  className={classes.avatar}>
           {post.userName}
          </Avatar>
        }
         
        action={
          <Grid container spacing={2}  direction="row" justify="center"  alignItems="center">
          <Grid item>
            {process.env.GATSBY_ADDS_ENABLE === 'true' && 
          <CardMedia   className={classes.adds}  style={{ border: 0, borderColor: "white" }}   component='iframe' title="Ads" 
                  src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds320x50.html?alt=media&token=6d965238-b267-4b19-aaa9-dc2886c6372c`}></CardMedia>
            }
                  </Grid>
        
          </Grid>
        }
        title= {post.userName} 
        subheader={`${timeAgo.format(utcTimeOrg(post.updated),'twitter')} : ${post.locationCountry},${post.locationDistrict}${post.locationCity?(','+post.locationCity):''}`} >
      
 
      </CardHeader>
        
     
        <Typography
            className={classes.titleUi}
            id="title-textarea"
            noWrap
            variant="h6"
        >
        {post.title}
        </Typography>
        <PostImage tileData={post.urlFiles} mainPostId={post.mainPostId} linkFiles={post.linkFiles} psHeight={psHeight}  source={'public'}  isRssFeed={isRSSFeed(post.userName)}/>
         
        { (post.linkFiles.length> 0) && 
          <Link className={classes.sourceUi} href={post.linkFiles[post.linkFiles.length-1]} rel="noopener" onClick={preventDefault}>
              Source Link
            </Link>
        }
      
       
       
      <CardContent>
     
      
      <Grid container>
        <Collapse in={!showMore} timeout="auto" unmountOnExit>
        <Typography  id={"FpostStory"+post._id} variant="body1"  align="justify"   display="block"  color="textPrimary" >  
        
          </Typography> 
        </Collapse>
        <Collapse in={showMore} timeout="auto" unmountOnExit>
         <Typography id={"TpostStory"+post._id}  variant="body1"   align="justify"    display="block"   color="textPrimary" >  
        
          </Typography> 
        </Collapse>
        </Grid>
        { post.story.length> 500 && 
         
            <Button  color="primary"   onClick={hanldeShowMore} fullWidth   >
              {!showMore ? 'more...' : 'less'}
           </Button>

        } 
        
      </CardContent>
      <CardActions disableSpacing >
    
 
            <Grid className={classes.gridUi} container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                      <IconButton  aria-label="view" onClick={()=>handlePostDialogView(true)}>
                        <Badge  color="secondary"  badgeContent={post.countView}  max={999} >
                          <VisibilityIcon  fontSize="small"   />
                              </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton   aria-label="feelings"  >
                        <Badge  color="secondary"  badgeContent={post.countLike}  max={999} >
                          <ThumbUpIcon fontSize="small" />
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton  aria-label="feelings"   >
                        <Badge  color="secondary"  badgeContent={post.countDisLike}  max={999}>
                          <ThumbDownIcon fontSize="small"  />
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                    <Tooltip title="Copy and paste, this Post url to share.">
                      <IconButton  aria-label="url link"  
                       
                           onClick={()=>copyToClipboard(`${process.env.GATSBY_HOST}?fid=${post._id}&where=mainPostId&pr=${post.sharedType.substr(0, 2)}`)} >
                          <LinkIcon fontSize="small"  color="primary"/>
                        
                      </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
 
                    </Grid>
                    <Grid item>
                      <IconButton  aria-label="add to favorites"  >
       
                          <Badge   badgeContent={post.countTag}  >
                            <LocalOfferIcon fontSize="small"   />
                          </Badge>
                      
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton aria-label="feelings"  >
                          <Badge badgeContent={post.shared}  max={999}>
                           <ShareIcon fontSize="small"   />
                          </Badge>
                      </IconButton>
                    </Grid>
        
              </Grid>
                 
      </CardActions>
      <Divider/>
      {post.countComment > 0   &&
       <Button  fullWidth color="primary" 
            className={classes.button} 
            aria-label="show more"
            onClick={handleExpandClick}
            endIcon={ <Icon className={classes.iconUi}>
              <ExpandMoreIcon  color="primary" className={clsx(  {
                [classes.expandOpen]: expanded,
              })}/>
            </Icon>}
        > 
            {post.countComment>0?post.countComment:""} Comments  {post.countShare>0?post.countShare:""} Shares              
        </Button>
      }
         

      <Collapse in={expanded} timeout="auto" unmountOnExit>
      
       
          <Grid container>
              {expanded &&
              <Comments  postId={post._id}/>    
            }  
          </Grid>

        {post.countComment > 3   ?
            <Button  fullWidth color="primary" 
            className={classes.button} 
            aria-label="show more"
            onClick={handleExpandClick}
            endIcon={ <Icon className={classes.iconUi}>
              <ExpandMoreIcon  color="primary" className={clsx(  {
                [classes.expandOpen]: expanded,
              })}/>
            </Icon>}
        > 
            {post.countComment>0?post.countComment:""} Comments  {post.countShare>0?post.countShare:""} Shares              
        </Button>
        :
        <></>
        }


      </Collapse>
      
    </Card>

  
  );
}


export default PostPublic;


