import { firebase}  from 'gatsby-theme-firebase';
import { Typography, Link } from '@material-ui/core';

export const utcTime = () =>
{   
   return firebase.firestore.Timestamp.now().toMillis();
  //  console.log("created: " + created);
  //  const v = new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000);
  //  console.log("created:" , v);
  //   return  v;
}

export const utcTimePlus = (timeStamp) =>
{
    //console.log("utcTime: " + timeStamp);
 return timeStamp;
   // return  timeStamp + (new Date().getTimezoneOffset() * 60 * 1000);
}

export const utcTimeOrg = (timeStamp) =>
{
    //console.log("utcTime: " + timeStamp);
    return timeStamp;
   // return  timeStamp - (new Date().getTimezoneOffset() * 60 * 1000);
}

export function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return -1;  
       else if(a[property] < b[property])  
          return 1;  
   
       return 0;  
    }  
  }

export  const warpText = (itext) => {
   if(itext===undefined || itext===null){
     return "";
   }
   
   if(itext.length>0){ 
   return itext.substr(0,60)+"...";
   }else{ 
   return "";
   }
 }



 export function Copyright() {
   return (
     <Typography variant="body2" color="textSecondary" align="center">
       {'Copyright © '}
       <Link color="inherit" href={process.env.GATSBY_HOST}>
       {process.env.GATSBY_HOST}
       </Link>{' '}
       {new Date().getFullYear()}
       {'.'}
     </Typography>
   );
 }