import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
//import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import YourPosts from '../templates/YourPosts';
import YourFriends from '../templates/YourFriends';
import YourTagPosts from '../templates/YourTagPosts';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .05)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const LeftDrawer = ({profileId,closedLeftDrawer}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel key="1" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Followed Post</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.root}>
        <YourTagPosts  userId={profileId} closedLeftDrawer={closedLeftDrawer} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel  key="2" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Your Post</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <YourPosts userId={profileId}  closedLeftDrawer={closedLeftDrawer}  />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
      <ExpansionPanel  key="3" expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
        <ExpansionPanelSummary
          expandIcon={
          <ExpandMoreIcon />
        }
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
         
            <Typography className={classes.heading}>Friends</Typography>
            

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <YourFriends userId={profileId}  closedLeftDrawer={closedLeftDrawer} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default LeftDrawer;