import React, {  useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
 
import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Box, TextField, InputBase, Paper, Divider, styled, List, ListItem, Snackbar , CircularProgress, Grid, Container} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link, Button } from 'gatsby-theme-material-ui';
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { ZoomControl } from "react-mapbox-gl";
import { ScaleControl } from "react-mapbox-gl";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { ControllerClassicOutline } from 'mdi-material-ui';
import {Place, LocationState} from '../utils/mantawiObject';
import ListIcon from '@material-ui/icons/List';
import { Alert, AlertTitle } from '@material-ui/lab';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Tooltip from '@material-ui/core/Tooltip';
// const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      
    },
    
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      textTransform: 'none'
    },
    iconButtonLast:{
      paddingLeft: 10,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 20,
      textTransform: 'none'
    },
    divider: {
      height: 28,
      margin: 4,
    },
    searchItemList: {
      color: 'black',
      cursor: 'pointer'
    },
    searchList: {
      listStyleType: 'none',
      padding: '6px 5px',
      backgroundColor: 'white',
      marginTop: '0px',
      cursor: 'pointer'
    } ,
  
    container: {
     
      position: 'relative',
      top: 5,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      flex: 1, 
      border: "1px solid rgb(212, 212, 212)",
    },
    mark: {
        backgroundColor: '#e74c3c',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        border: '4px solid #eaa29b'
    },
    buttonUi:{
      textTransform: 'none',
    },
    appBarClipped: {
      zIndex: theme.zIndex.drawer + 1,
      height: '50px', 
      marginTop: 10,
      width: '80vw',
      left: 10,
      backgroundColor: 'white',
    },
    searchBoxUi:{
      backgroundColor: 'white',
    }
  
  }),
);

// const Container = styled.div`
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 40px;
//   background-color: white;
//   flex: 1;
// `;

// const List = styled.ul`
//   list-style-type: none;
//   padding: 6px 20px;
//   background-color: white;
//   margin-top: 0px;
// `;

// const Item = styled.li`
//   padding: 4px 0px;
//   color: #666;
//   font-size: 13px;
//   cursor: pointer;
// `;

//const token ='pk.eyJ1IjoianVuYWxkY2wiLCJhIjoiY2s4OHF3cmZrMGFhYzNvbXE5YzV3ZnA2dSJ9.anlICvJ8_VjbUvt5wUWVXQ';

const token = process.env.GATSBY_MAPBOX_API_KEY;

//https://alex3165.github.io/react-mapbox-gl/
//https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md

//(props: { handleDialogMap: (arg0: string) => void; }) 

//geocoding/v5/{endpoint}/{longitude},{latitude}.json

const geocodingUrl = 'https://api.mapbox.com/geocoding/v5';
const geocodingOptions = '&limit=5'
const geocodingOptions3 = '&autocomplete=true&limit=10'
const geocodingOptions2 = '&autocomplete=true&types=country,region,district,locality&limit=10'
const geocodingOptions4 = '&autocomplete=true&types=poi&limit=10'
 
const mapboxGeocoding = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingOptions2}`;
const mapboxGeocodingR = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}&limit=1`;
 //https://api.mapbox.com/geocoding/v5/mapbox.places/mandaue%20city.json?access_token=pk.eyJ1Ijoic2VhcmNoLW1hY2hpbmUtdXNlci0xIiwiYSI6ImNrN2Y1Nmp4YjB3aG4zZ253YnJoY21kbzkifQ.JM5ZeqwEEm-Tonrk5wOOMw&cachebuster=1586873906469&autocomplete=true&types=place&limit=5

// interface Place {
//   id: string;
//   name: string;
//   center: [number, number];
// }

// interface LocationState {
//   query: string;
//   options: Place[];
//   selected?: Place;
//   center: [number,number];
// }
// const Map = ReactMapboxGl({
//   accessToken: token ,
// });

//123.95,10.32 

//var Map = undefined;

const MyMapBox = (props: { openMapDialog: boolean; handleDialogMap: (arg0: string, arg1: any) => void  ; source: string} )  =>{
    const classes = useStyles();
  
    const [latitude,setLatitude] = useState(59.955413);
    const [longitude, setLongitude] = useState(30.337844);
    const [center, setCenter] = useState<[number,number]>([123.95,10.32]);
    const [zoomLevel, setZoomLevel] = useState<[number]>([11])
    const [searchValue , setSearchValue] = useState('');
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState<Place>(undefined);
    const [displayStyle, setDisplayStyle] = useState("none");
    const [showList, setShowList] = useState(false);
    const [isGeoLocating,setIsGeoLocating] = useState(false);
    const [showText,setShowText] = useState(false);

    if(globalThis.MAP === undefined){ 
      console.log("map is load once.......*****************");
      globalThis.MAP = ReactMapboxGl({
        accessToken: token  
        
      });
    }

    if(props.source==='posts'){

    }
  /**
    useEffect(()=>{
 
      console.log("map is load once dddddddddd");
      if(globalThis.MAP === undefined){ 
        console.log("map is load once.......*****************");
        globalThis.MAP = ReactMapboxGl({
          accessToken: token  
          
        });
      }

    },[]);
 */
    var textEventValue = null;
    const onSearch = (event) => {
     // console.log("onsearch " + event.target.value);
     
        if(event.target.value.length > 2){
          fetchQuery(event.target.value);
          setSearchValue(event.target.value);
        }
        setDisplayStyle("inline")
    
    };

    const onSelectItem = (event,index) => {
      console.log("onselecteditem: " + index);
      setShowList(false);
      const selectedCenter = options[index];
      setSearchValue(selectedCenter.name);
      setCenter(selectedCenter.center);
      setSelected(selectedCenter);
      setShowText(true);
      setOpen(true);
 
    };
 
   const req = (url: string, body?: any, method = 'GET') =>
    new Request(url, {
      method,
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Charset': 'utf-8'
      }),
      body
    });
    
    const fetchQuery = (query: string) => {
      setShowList(true);
      fetch(req(mapboxGeocoding(query)))
        .then((res: any) => res.json())
        .then((data: any) => {
          var datamap =  data.features.map((poi: any) => ({
            id: poi.id,
            center: poi.center,
            name: poi.place_name,
            place_name: poi.place_name
          }));
          setOptions( datamap );
        });
    };

    const fetchQueryR = (query: string) => {
      setShowList(true);
      fetch(req(mapboxGeocodingR(query)))
        .then((res: any) => res.json())
        .then((data: any) => {
          console.log(data);
          var datamap =  data.features.map((poi: any) => ({
            id: poi.id,
            center: poi.center,
            name: poi.place_name,
            place_name: poi.place_name
          }));
          setOptions( datamap );
          let _selected = datamap[0] ;
        
          setSelected(_selected);
         
          return _selected;
        }).then( (_selected) =>{
           console.log(_selected.center);
           setCenter(_selected.center);
           setSearchValue(_selected.name);
           setShowText(true);
           setOpen(true);
           setIsGeoLocating(false);
        }).catch( (error) =>{
          console.error(error);
        });
    };

    const fetchQuery3 = (query: string) => {
      fetch(req(mapboxGeocoding(query)))
        .then((res: any) => res.json())
        .then((data: any) => {
          setOptions( 
             data.features
              
              .map((poi: any) => ({
                id: poi.id,
                center: poi.center,
                name: poi.text,
                place_name: poi.place_name
              }))
           );
  
        });
    };

    const fetchQuery2 = (query: string) => {
      fetch(req(mapboxGeocoding(query)))
        .then((res: any) => res.json())
        .then((data: any) => {
          setOptions( 
             data.features
              .filter((place: any) => place.place_type.includes('poi'))
              .map((poi: any) => ({
                id: poi.id,
                center: poi.center,
                name: poi.text
              }))
           );
  
        });
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const handleCloseSelect =  () => {
      props.handleDialogMap("layoutSave",selected);
      setSearchValue('');
     // setSelected(undefined);
      setOpen(false);
    };


    const handleUserLocation = () => {
      setIsGeoLocating(true);
      navigator.geolocation.getCurrentPosition(position => {
          let newViewport = {
              height: "100vh",
              width: "100vw",
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              zoom: 12
          }
          console.log(newViewport);
     
          fetchQueryR(`${position.coords.longitude},${position.coords.latitude}`);
      })
    }

    return (
      <div className={classes.root}>
        <Dialog  style={{ background: 'transparent', boxShadow: 'none'}} fullScreen open={props.openMapDialog} onClose={() => props.handleDialogMap("map","close without selecting")} TransitionComponent={Transition}>
      <CssBaseline />
                   
          <AppBar
              position="absolute"
              className={classes.appBarClipped}>

              <Grid  container className={classes.searchBoxUi} justify="flex-end" alignItems="center" >
                
                    <Grid item xs={12} md={6} sm={6} container direction="row"   justify="flex-start"> 
                      <Tooltip title="History Location">
                        <IconButton className={classes.iconButton} aria-label="menu">
                          <MenuIcon />
                        </IconButton>
                     </Tooltip>
                        {showText?
                            <InputBase
                              className={classes.input}
                              placeholder="Search your current location"
                              value={searchValue}
                              onClick={()=>{setShowText(false)}}
                            /> 
                            :
                            <InputBase
                              className={classes.input}
                              placeholder="Search your current location"
                              onChange={onSearch}
                              defaultValue={searchValue}
                              onClick={()=>{setShowText(false)}}
                            /> 
                        }
                   </Grid>

                   <Grid item xs={12} md={6} sm={6} container direction="row"   justify="flex-end"> 
                      <Divider className={classes.divider} orientation="vertical" />
                    
                          <Button
                            color="primary"
                            onClick={handleCloseSelect} 
                            className={classes.iconButton} 
                            >
                              <Tooltip title="Select Location"> 
                                <DirectionsIcon/>     
                              </Tooltip>
                          </Button> 
                       
                      <Divider className={classes.divider} orientation="vertical" />
                        <Tooltip title="Auto Geo locate">
                         <IconButton color="primary" className={classes.iconButton} aria-label="search" onClick={handleUserLocation}>
                              {  isGeoLocating ? <CircularProgress size={30}/> : <MyLocationIcon />}
                          </IconButton>
                        </Tooltip>
                      <Divider className={classes.divider} orientation="vertical" />
                        <Tooltip title="Close">
                        <IconButton color="primary" edge="end" className={classes.iconButtonLast}  onClick={() =>  props.handleDialogMap("map","close without selecting")} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        </Tooltip>
                   </Grid>
                  
                        { 
                          ( searchValue.length > 0 && showList) &&  
                          <Container className ={classes.container}  >
                              <List>
                                {
                                  options.map((el, index) => (
                                    <ListItem
                                      key={index}
                                      onClick={(event) => onSelectItem(event, index)}
                                      className={classes.searchItemList}
                                      button
                                    >
                                      {el.place_name}
                                    </ListItem>
                                  ))
                                }
                              </List>
                              </Container>  
                              }
            </Grid>
          </AppBar>       
                  <globalThis.MAP 
                      style="mapbox://styles/mapbox/streets-v11"
                      zoom={zoomLevel}
                      center={center}
                      containerStyle={{
                        height: '100%',
                        width: '100%',
                      }}

                    >
                      
                      <ZoomControl/>
                      <ScaleControl/>
                      
                    {/**<Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                        <Feature coordinates={[-0.481747846041145, 51.3233379650232]} />
                      </Layer> */} 
                      {selected && (
                        <Marker coordinates={selected.center}>
                          <div className={classes.mark} />
                        </Marker>
                      )}
                  
                    </globalThis.MAP>
                           
                   
        <Snackbar open={open}   onClose={handleClose}
       
        >  
          <Alert  onClose={handleClose} severity="success"
           action={
            <React.Fragment>
              <Button className={classes.buttonUi} color="primary" size="small" onClick={handleCloseSelect}>
                Select Location
              </Button>
              <IconButton  size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          >
          <AlertTitle> You have selected this location </AlertTitle>
             <strong>{selected ? selected.name: ''}</strong>
          </Alert>
        </Snackbar>
        </Dialog> 
     </div>
    )
}

export default MyMapBox

// context.1
// place = city name
// region = region
// country = 