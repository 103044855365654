import React, {useState}  from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
 
import { CardMedia, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        height: "650px",
       
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    
  }),
);
 
const helpImageUrl =  () => {
 
  const pdf = require(`../images/docs/mantawi.pdf`);
  return pdf;
}

export default function HelpPage() {

  const classes = useStyles();

  const [helpPdf, setHelpPdf] = useState(helpImageUrl());

  return (
      
       <Container>
       <Grid container direction="row" justify="center" alignItems="center">  
             
             <Grid item >     
          
              
                 <Typography variant="h5">--DRAFT, HELP DOCUMENT--</Typography> 
              
             </Grid>
             
           </Grid>
        <CardMedia  style={{ border: 0, borderColor: "white" }} className={classes.root} component='iframe' title="help" 
            src={helpPdf} />
{/**
<CardMedia  style={{ border: 0, borderColor: "white" }} className={classes.root} component='iframe' title="help" 
            src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2fmantawi.pdf?alt=media`} />
 */}
        <Grid container direction="row" justify="center" alignItems="center">  
             
             <Grid item >     
          
                 <Typography variant="caption">natnat.conde@gmail.com</Typography> 
              
             </Grid>
             
           </Grid>
      </Container>
       
     
  );
}
