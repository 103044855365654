//import withRoot from "../utils/withRoot";
import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import SEO from "./SEO";
import Card from "./Card";
 
import Button from "@material-ui/core/Button";
 
import Avatar from "@material-ui/core/Avatar";
import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { makeStyles, Theme, createStyles, CircularProgress, Container, Grid, CardMedia, CardContent } from "@material-ui/core";
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
import CarouselPost from "./CarouselPost";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: "bold",
      textTransform: "none"

    },
 
    contentBox: {
      maxWidth: "calc(1136px - 60px)",
      width: "calc(100% - 60px)",
 
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      marginLeft: '50%',
      height: '350px',
    },
    headerSpacer3: {
      flex: 1,
      display: 'fixed',
      marginTop: '35%',
 
    },
    containerUi: {
    
      marginLeft: -15,

    },
    adds:{
      width: 750,
      height: 107,
      borderColor: "white",
      border: 0,
    }
  }),
);
 


const  Welcome = (props) => {

  const classes = useStyles();
  var [postsData, setPostsData] = useState<firebase.firestore.DocumentData[]>([]);
//  const [sortPostsData, setSortPostsData] = useState([]);
  var [isLoading,setIsLoading] = useState(false);
  var [error, setError] = React.useState<Error | null>(null);

  const useFirestoreQuery3 = <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    
    const unsubscribe = query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        setIsLoading(false);
        setPostsData(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
      
      },
      (err: Error) => {
        console.log("useFirestoreQuery3: " + err);
        console.error(err);
        setError(err);
      },()=>{
      
      }
    );  
    return () => unsubscribe();
  }

  useEffect(() => {
    console.log("wheretext and whervalue change");
       setIsLoading(true);
       useFirestoreQuery3(firestore.collection('posts').where( "postType","==", "post").where("sharedType","==","public").orderBy("countLike","desc").limit(+process.env.GATSBY_LIMIT_TOPS) );
       
  },[]);

 
 
  return (
   <Container>
  
      <SEO title="Home">
        <meta
          name="description"
          content="Welcome, feature, latest news, top news,"
        />
        
           <meta
            name="url"
            content={`${process.env.GATSBY_HOST}?mid=welcome`}
          />
      </SEO>

      <Container className={classes.containerUi}>
            
      <Grid container spacing={2}  direction="row" justify="center"  alignItems="center">
              <Grid item>
              {process.env.GATSBY_ADDS_ENABLE === 'true' &&
                      <CardMedia className={classes.adds}  component='iframe' title="Ads" 
                      src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds728x90.html?alt=media&token=c04a5eb6-5904-45dd-bbe8-aa48455cf58c`}></CardMedia>
                       }
              </Grid>
        </Grid>
    
      <Card
        title="Latest and Treding Post."
        avatar={
          <Avatar>
            <NewReleasesIcon />
          </Avatar>
        }
        action={
          <Button
            variant="outlined"
            color="secondary"
            className={classes.root}
            component={Link}
            to= { `/?fid=post&where=postType`} 
          >
            View All Post
          </Button>
        }
       
        className={ classes.contentBox}
      >
        <CardContent>
        {isLoading  ? <div className={classes.headerSpacer2} >  <CircularProgress className={classes.headerSpacer3} color="primary" />   </div> 
        :
        postsData && postsData.length > 0 &&<CarouselPost posts={postsData} /> } 
       </CardContent>
      </Card>
     </Container>
     </Container>
  );
  };

 
export default Welcome;
 
