import React, {useEffect,useState}  from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withPrefix } from "gatsby";
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import { LinearProgress, Grid, Container, CardMedia, Card } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      maxHeight: 210,
      minHeight: 210,
      border: "1px solid rgb(212, 212, 212)",
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    prgBar: {
      width: '100%',
      height: '10px',
      backgroundColor: red[500],
      transform: 'translateZ(0)',
    },
    cover: {
      width: 151,
    },
    imageUi1:{
      maxHeight: 220,
      minHeight: 220,
      maxWidth: '100% - 20',
      minWidth: '100% - 20'
    },
  }),
);

  
const PostingImage = ({tileData,linkFiles} ) =>  {
  const classes = useStyles();

  const [progress, setProgress] = useState(0);

  const [ showLink, setShowLink] = useState(false);

  useEffect(()=>{
    
      
    if(linkFiles === undefined){

    }else{ 
      if(linkFiles.length>0){
        setShowLink(true);
      }else{
        setShowLink(false);
      }
   }
    
  },[linkFiles]);
  
  return (
    <div className={classes.root}>
 

          { 
            showLink && (linkFiles[linkFiles.length-1].indexOf('youtube.com')>0 || linkFiles[linkFiles.length-1].indexOf('vimeo.com')>0 )
              &&  <ReactPlayer className={classes.imageUi1}  url={linkFiles[linkFiles.length-1]} light controls  />
          }
          {
            showLink &&  <CardMedia height='300'  width='300' component='img' title="linkfiles" src={linkFiles[linkFiles.length-1]}/>
   
          }
        <GridList cellHeight={220}  className={classes.gridList} cols={2.5}> 
        
         {tileData.map((tile) => (
   
          <GridListTile key={tile.fileName}   style={{ height: '200px', width: '150px' }} >

          <Grid container> 
              <Grid item>
             <Grid item xs={12}>
               <Card>
                <CardMedia
                   height='180'
                   width='auto'
                   component={tile.component}
                   title={tile.fileName}
                   src={tile.img}
                  />
                </Card>
            {/**
              <img src={tile.img} alt={tile.fileName}  style={{ height: '180px', width: '100%' }} />
            */}  </Grid>     
            <Grid item xs={12}>
              { (tile.progress > 0 )&&
              <LinearProgress className={classes.prgBar} variant="determinate" value={tile.progress}  />   
              }    
            </Grid>
            </Grid>
          </Grid>
      
          </GridListTile>
    
        ))}
      </GridList>
    </div>
  );
}

export default PostingImage;
// title={tile.title}
// actionIcon={
//   <IconButton aria-label={`star ${tile.title}`}>
//     <StarBorderIcon className={classes.title} />
//   </IconButton>
// }

//video, audio, picture, iframe, or img,  == page, youtube.
// link