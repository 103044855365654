import React, { useState, useEffect, useContext } from 'react';
 
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {UserProfile} from '../utils/mantawiObject';
 
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CancelIcon from '@material-ui/icons/Cancel';
import {Snackbar,  IconButton, Grid, Paper, LinearProgress} from '@material-ui/core';
import { Link  } from 'gatsby-theme-material-ui';

import SearchIcon from '@material-ui/icons/Search';
import MyMapBox from './myMapBox';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootEntry: {
      display: 'flex',
      flexGrow: 1,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    prgBar: {
      width: '250px',
      height: '10px'
    },
    paper: {
      backgroundColor: 'black',
       
    },
    expandButton: {
      
      minWidth: '100px'
    },
    snackTop:{
      marginTop: 50,
    },
    contactUi:{
      width: '90%',
      height: '100vh'
    },
  }),
);

let storage = undefined;
var d = new Date();
var m = d.getMonth() + 1;
var today = d.getFullYear()+'-'+ 
    (m<10? ('0'+m):m)+'-'+
    (d.getDate()<10? ('0'+d.getDate()):d.getDate())

const Account = ({setShowPage}) => {
    const classes = useStyles();
    const [url, setUrl] = useState("");
    const [progress, setProgress] = useState(0);
    const [checkedB, setCheckedB] = useState(false);
    const [userProfile, setUserProfile] = useState<UserProfile>( {
 
        name: "",
        email: "",
        dob: "",
        profilePicture: "",
        location: "",
        locationCenter: [0,0],
        created: null,
        updated: null,
        useLocation: true,
        locationCity: "",
        locationCountry: "",
        locationDistrict: "",
        locationOther: "",
    });
  
    
    const [loading, setLoading] = useState( false );
    const [errorMsg, setError] = useState(null);
    const [image, setImage] = useState(null);
    const [displayName,setDisplayName] = useState("");
    const { isLoggedIn, profile }  = useAuth();
    const [center, setCenter] = useState([0,0]);
  
    const [openMap, setOpenMap]= useState(false);
    const [open, setOpen] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [severity, setSeverity] = React.useState("warning");
    const [message, setMessage] = React.useState("");

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        setOpen(false);
      
        return;
      }
  
   
    };

    const handleMapDialog = (dialogBox: string,sdLocation: any) =>{
      setOpenMap(true);
     
    }

    const handleMapCloseDialog = (dialogBox: string,sdLocation: any) =>{
      setOpenMap(false);
     
      handleLocation(sdLocation);
    }

    const handleLocation =(location)=>{
    
      if(  location !== undefined  && location !== null && location.name !== "" && location.name !== userProfile.location){
       

        var _location = location.name.split(',');
        var pCountry = "";
        var pDistrict ="";
        var pCity = "";
        var pOther ="";

        if(_location.length>0)
          pCountry = _location.pop();
        if(_location.length>0){ 
          pDistrict = _location.pop();
          if(pDistrict.indexOf('City') !== -1){
            pCity = pDistrict;
            pDistrict = "";
          }
        }
        if(_location.length>0 && pCity.length===0){ 
          pCity = _location.pop();
        }
        if(_location.length>0)
          pOther = _location.pop();
       
         setUserProfile(
          {...userProfile,
          ['location']: location.name,
          ['locationCenter']: location.center,
          ['locationCountry']: pCountry.trim(),
          ['locationDistrict']: pDistrict.trim(),
          ['locationCity']: pCity.trim(),
          ['locationOther']: pOther.trim(),
          }
        );
      }
    }

    const handleCheckedB = () =>{
      var _useLocation = !checkedB;
      setCheckedB(_useLocation);
      setUserProfile(
        {...userProfile,
        ['useLocation']: _useLocation
       
        }
      );
    }
  
    useEffect(()=>{
 
      if(storage === undefined){ 
        storage = firebase.storage();
      }
 
      if(profile){
        setDisplayName(profile.displayName);
        console.log("mount useefffect profile: " +profile.uid );
        
        firestore.collection("users").doc(profile.uid).get().then(
          function(doc){

            if (doc.exists) {
              var data = doc.data();
              var _useLocation = (data.useLocation!==null && data.useLocation!==undefined)?data.useLocation:false ;
              setUserProfile({
                  ...userProfile, 
                  email: data.email,
                  name: data.name,
                  dob: data.dob,
                  profilePicture: data.profilePicture,    
                  location: data.location,
                  locationCenter: data.center || [0,0],
                  created: data.created,
                  updated: data.updated,
                  useLocation: _useLocation,
                  locationCity: data.locationCity || "",
                  locationCountry: data.locationCountry || "",
                  locationDistrict: data.locationDistrict || "",
                  locationOther: data.locationOther || "",
                  
              });
              setUrl(data.profilePicture);
              setCheckedB( _useLocation);
            } else {

              console.log("this should not happened.....")
              setUserProfile({
                name:  profile.displayName,
                email: "",
                dob:  "01/26/1901" ,
                profilePicture: "",
                location: "",
                locationCenter: [0,0],
                created: null,
                updated: null,
                useLocation: true,
                locationCity: "",
                locationCountry: "",
                locationDistrict: "",
                locationOther: "",
              })
   
            }
          }

        ).catch( (error)=> {
          console.error(error);
        }); 
   
      }else{
        setDisplayName('fetching...');
      }
      console.log("mount useefffect account");
   

    },[profile]);

    const saveUserProfile =  () => {  
      // console.log("saveUserProfile");
      // console.log(JSON.stringify(userProfile));
      // console.log(globalThis.PROFILE_UID);
      firestore.collection("users").doc(globalThis.PROFILE_UID)
        .update( userProfile ).then(()=>{
          console.log("updated........");
          setSeverity("success");
          setMessage("Account detail updated.")
          setOpen(true);
        });
          
    }  

    const handleInputChange = (event) =>{  
        const value = event.target.value;
        const name = event.target.name;     
    
        setUserProfile(
          {...userProfile,
          [name]: value}
        );
          
    }
 
    const spacing = 2;

    const handleUpload = (theImage) => {
     // const newName = `${profile.uid}.${theImage.name.split('.').pop()}` 
     const newName = `${profile.uid}` 
     const uploadTask = storage.ref(`images/profiles/${newName}`).put(theImage);
      uploadTask.on(
        "state_changed",
        snapshot => {

          const progressCount = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
       
          setProgress(progressCount);
        },
        error => {
          // Error function ...
          console.error(error);
        },
        () => {
          // complete function ...
          storage
            .ref("images/profiles")
            .child(newName)
            .getDownloadURL()
            .then(url => {
              console.log("account profile: " + url);
              setUrl(url);
              userProfile['profilePicture']= url
              setUserProfile({...userProfile} );
            }).then( ()=>{
              //saveUserProfile();
            });
        }
      );
    };

    const handleChange = (event)  => {
      if (event.target.files[0]) {
        const imageFiles = event.target.files[0];
        if (imageFiles.size > 150000) // 100,000 for bytes.
        {
          setSeverity("warning");
          setMessage("Profile image size exceed 150 kilobytes.")
          setOpen(true);
        
        } else{

          setImage(imageFiles);
          handleUpload(imageFiles);
        }
 
      }
    };
  
    return (   
      <Grid  container  justify="center"  alignItems="center">
      <Container className={classes.contactUi} >
           <Card>
            <CardHeader  
              title={
                  <h5>
                    Account Information - {isLoggedIn && displayName}
                  </h5> 
                }     
         
              />
  
              <CardContent>
                <Grid container  spacing={2}>
                    <Grid key={'profilePicture'} item >   
                      <Grid key={'profilePicture-image'} item >
                        <>
                          <label htmlFor="icon-button-file">
                            <img
                              src={url || "https://via.placeholder.com/250x250"}
                              alt="Uploaded Images"
                              height="250"
                              width="250"
                            />
                          </label>
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            id="icon-button-file"
                            style={{ display: 'none', }}
                          />   
                        </>
                      </Grid>
                      <Grid key={'profilePicture-progress'} item>
                           <LinearProgress className={classes.prgBar}  variant="determinate" value={progress}  />
                      </Grid>
                    </Grid>


                    <Grid key={'textfields'} item xs={12} sm container>
                      <Grid item xs container  spacing={2}>
                         <Grid container>
                              <Grid item key={'textfields1'} xs={12}>
                                 <TextField   className={classes.rootEntry} id="name" name="name" label="Name" value={userProfile.name}  onChange={handleInputChange}/>
                              </Grid>
                              <Grid item key={'textfields2'} xs={12}>
                                 <TextField className={classes.rootEntry} id="dob" name="dob" label="Date of Birth" type="date" value={userProfile.dob}  onChange={handleInputChange}/>
                              </Grid>
                              
                              
                              <Grid item key={'textfields3'} xs={11}>
                                  <TextField InputProps={{ readOnly: true,}} className={classes.rootEntry} id="location"  name="location" label="Location" value={userProfile.location}  onChange={handleInputChange}/>

                              </Grid>
        
                              <Grid item key={'textfields4'} xs={1}>
                                  <IconButton  color="primary" aria-label="search location" onClick={() => handleMapDialog("posting","") } >
                                    <SearchIcon />
                                  </IconButton>
                                 
                              </Grid>
                              <Grid item key={'textfields3'} xs={11}>
                              <FormControlLabel
        control={
          <Checkbox
            checked={checkedB}
            onChange={handleCheckedB}
            name="checkedB"
            color="primary"
            value={userProfile.useLocation}
          />
        }
        label="Use your location as a default location for creating a new Post."
      />
                              </Grid>
          
               {/**
                              <Grid item  key={'textfield5'} xs={12}>
                                  <TextField InputProps={{ readOnly: true,}} className={classes.rootEntry} id="profile"  name="profile"  label="Profile" value={userProfile.profilePicture}  onChange={handleInputChange}/>
                              </Grid> */}
                            </Grid>
                      </Grid>
                    </Grid>
                 </Grid>  
              </CardContent>
              <CardActions disableSpacing>
                  
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item>
                  <Button className={classes.expandButton} variant="outlined" color="primary" onClick={saveUserProfile }>
                       Send
                  </Button>
                  </Grid>
                  <Grid item>
                  <Button className={classes.expandButton} variant="outlined" color="primary" onClick={()=>{setShowPage(2)} }>
                       Cancel
                  </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapCloseDialog}  source="account"  />
            <Snackbar className={classes.snackTop} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open}   onClose={handleClose} >  
              <Alert  onClose={handleClose}  severity={severity}>
              <AlertTitle>{severity}</AlertTitle>
              <strong>{message}</strong>
            </Alert>
       </Snackbar>
       </Container>
       </Grid>
    )
}

export default Account;
