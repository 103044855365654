import React,{useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Paper, Link, Tooltip } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {utcTime, utcTimePlus, sortByProperty, warpText} from '../utils/MantawiTime'; 
import { navigate } from '@reach/router';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
    },
    listItemUi:{
      
      width: "auto",
      flex: 1,
    },   
    loadingUi:{
        width: "100%",        
    },
    typoUi:{
        maxHeight: 100,
        display:"block",
        overflow: 'hidden',
    },
    notificationUi:{
       flex: 1,
    },
    
    gridUi:{
      flex: 1,
      width: "100%"
    },
    inline: {
                                                              
    },
    buttonUi: {
    
      textTransform: "none",
    },
    
  }),
);
   
TimeAgo.addLocale(en);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const YourTagPosts = ({userId,closedLeftDrawer}) => {

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
   
  const [posts, isLoading] = useFirestoreQuery(

    firestore.collection("posts").where("userId", "==", userId).orderBy("updated","desc").limit(+process.env.GATSBY_LIMIT_POSTS)
    
  );
//  const [sortPosts, setSortPosts] = useState([]);

//   useEffect(()=>{
    
//       if(posts && posts.length>0){
//         posts.sort(sortByProperty("updated"))
//         setSortPosts(posts);
//       }
    
//  },[posts]);

 const handlePost = (post)=>{
   var to=`/?kil=${userId}&fid=${post._id}&where=mainPostId`;
   if(post.hasUpdates){ 
   firestore.collection("posts").doc(post._id).update( {hasUpdates:false} )
        .then(()=>{
          console.log("post updated........");
        }).catch((error)=>{
          console.log("post updated error: " + error);
          console.error(error);
        });
      }
   navigate(to);
   closedLeftDrawer();
 }

  return (
    <div className={classes.root}>
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
     
    <List className={classes.listUi} dense>
        {  posts.map((post) =>(
           <Link key={post._id} style={{ textDecoration: 'none' }}  onClick={ ()=>{handlePost(post)}}>    
          <ListItem className={classes.listItemUi} key={post._id} button alignItems="flex-start">
             {/**
               <ListItemAvatar>
             <Badge color="secondary" variant="dot">
                 <Avatar  alt={`Avatar n°${post.authorId}`}
                    src={`https://firebasestorage.googleapis.com/v0/b/mantawi-d38b7.appspot.com/o/images%2Fprofiles%2F${post.authorId}.jpg?alt=media`}>
                      {post.authorName}
                  </Avatar>
                  </Badge>
              </ListItemAvatar>
             */}
    <HtmlTooltip title={
       <React.Fragment>
         <Typography color="primary" variant="caption"> {`Views:${post.countView} Likes:${post.countLike} Dislikes:${post.countDisLike} Tags:${post.countTag} Comments:${post.countComment}`}  </Typography>  
         <Typography variant="caption"> {post.story} </Typography>  
     </React.Fragment>
       } >
   
              <ListItemText className={classes.typoUi}   
                          primary={ 
                                  
                            <Typography
                            variant="subtitle2"
                            display="inline"
                            color="textPrimary"
                              >
                        {timeAgo.format(utcTimePlus(post.updated), 'twitter')} 
                    </Typography>
                                


                          }
                    
                          secondary={  
                                          <Typography
                                            component="span"
                                            variant="caption"
                                            display="block"
                                            color="textPrimary"
                                            noWrap
                                            >
                                            {/**warpText(post.title)*/
                                            post.title
                                            }
                                          </Typography>
                                    
                          }
              />
     </HtmlTooltip>
              <ListItemSecondaryAction>
                {/**<Grid  container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                      <Grid item> 
                      
                      </Grid>
                      <Grid item> 
                        <VisibilityIcon  fontSize="small"/> {post.countView}
                      </Grid>
                                                    
                      <Grid item>
                        <LocalOfferIcon fontSize="small"/> {post.countTag} 
                      </Grid>

                      <Grid item>
                        {post.hasUpdates?<NotificationsActiveIcon color="primary" fontSize="small"  />:
                        <NotificationsIcon className={classes.notificationUi} color="primary" fontSize="small" /> }
                      </Grid>
                  </Grid> */} 
                  {post.hasUpdates?<NotificationsActiveIcon color="primary" fontSize="small"  />:
                        <NotificationsIcon className={classes.notificationUi} color="primary" fontSize="small" /> }
              </ListItemSecondaryAction>
                      
            </ListItem>
          </Link>
            
        ))}
      </List>
     
    }
   </div>
  );
} 
export default YourTagPosts;
